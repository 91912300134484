<template>
	<div class="modal-tbf modal-full-page modal_add_meeting_view_only">
		<div class="container-modal opacity-page" v-if="loaded">
			<template v-if="meetingError">
				<div class="error-content">
					<div class="image"><img src="/build/images/warning.svg" /></div>
					<div class="description">
						{{ $t("add_meeting.meeting_error") }}
					</div>
					<div class="form-submit">
						<button id="buttonPrimarySubmit" class="btn-tbf blue center" @click="refreshCalendar()">
							<div class="text">{{ $t("general.update") }}</div>
						</button>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="header-modal">
					<div class="title-box">
						<div class="title">
							{{ meeting.title }}
						</div>
					</div>
					<div class="actions">
						<template v-if="meeting.can_lead">
							<button class="btn-tbf green join-btn" @click="startMeeting()" v-if="meeting.status == 'not_started'">
								<div class="text">
									{{ $t("add_meeting.start_meeting") }}
								</div>
							</button>
							<button class="btn-tbf white-blue join-btn started" v-else-if="meeting.status == 'ongoing'">
								<div class="text">
									{{ $t("add_meeting.meeting_started") }}
								</div>
							</button>
							<button class="btn-tbf white-green join-btn finished" v-else-if="meeting.status == 'finished'">
								<div class="text">
									{{ $t("add_meeting.meeting_finished") }}
								</div>
							</button>
						</template>

						<button class="btn-tbf white only-icon edit-btn" @click="openEditMeeting()" v-if="data.right == 1 || meeting.can_lead"><icon-edit class="icon-close" /></button>
						<button class="btn-tbf white only-icon close-btn" @click="closeModal()"><icon-close class="icon-close" /></button>
					</div>
				</div>
				<div class="info-content">
					<div class="first-row">
						<div class="group w-120">
							<icon-calendar class="icon" />
							<div class="description">
								{{ moment(data.date, "YYYY-MM-DD").format("D MMMM YYYY") }}
							</div>
						</div>
						<div class="group w-120">
							<icon-clock class="icon" />
							<div class="description">
								{{ meeting.startHour + " - " + meeting.endHour }}
							</div>
						</div>
						<div class="group" v-if="meeting.location.physical">
							<icon-location class="icon" />
							<div class="description">
								{{ meeting.location.physical }}
							</div>
						</div>
					</div>

					<div class="info-row" v-if="meeting.location.online">
						<div class="title">{{ $t("add_meeting.online_location") }}:</div>
						<a class="description" :href="meeting.location.online" target="_blank">
							{{ meeting.location.online }}
						</a>
					</div>
					<div class="info-row" v-if="meeting.status == 'ongoing'">
						<div class="title">{{ $t("add_meeting.meeting_link") }}:</div>
						<div class="description">
							<a :href="meeting.publicLink">{{ meeting.publicLink }}</a>
						</div>
					</div>

					<div class="info-row" v-if="meeting.description">
						<div class="title">{{ $t("general.description") }}:</div>
						<div class="description" v-html="meeting.description"></div>
					</div>

					<div class="info-row">
						<div class="title">{{ $t("add_meeting.participants") }}:</div>
						<div class="participants">
							<div class="participant owner">
								<div class="avatar">
									<img :src="meeting.added_by.avatar" />
								</div>
								<div class="data">
									<div class="name">{{ meeting.added_by.name }}</div>
									<div class="description">{{ $t("add_meeting.organizer") }}</div>
								</div>
							</div>
							<div v-for="guest in meeting.guests.filter((el) => el.id != meeting.added_by.id)" class="participant">
								<div class="avatar">
									<img :src="guest.avatar" />
									<v-popover
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 0, hide: 0 }"
										:class="`status ${guest.response ? guest.response : ''}`"
									>
										<icon-check v-if="guest.response == 'yes'" />
										<icon-close v-else-if="guest.response == 'no'" />
										<icon-holiday v-else-if="guest.response == 'holiday'" />
										<icon-question v-else />

										<template slot="popover">
											<div class="simple-text" v-if="guest.response == 'yes'">{{ $t("meeting_session.presence_status.yes") }}</div>
											<div class="simple-text" v-else-if="guest.response == 'no'">{{ $t("meeting_session.presence_status.no") }}</div>
											<div class="simple-text" v-else-if="guest.response == 'holiday'">{{ $t("meeting_session.presence_status.holiday") }}</div>
											<div class="simple-text" v-else-if="guest.response == 'maybe'">{{ $t("meeting_session.presence_status.maybe") }}</div>
											<div class="simple-text" v-else>{{ $t("meeting_session.presence_status.unconfirmed") }}</div>
										</template>
									</v-popover>
								</div>
								<div class="name">{{ guest.name }}</div>
							</div>
						</div>
					</div>

					<div class="info-row" v-if="meeting.externalGuests.length">
						<div class="title">{{ $t("add_meeting.external_participants") }}:</div>
						<div class="participants">
							<div v-for="externalGuest in meeting.externalGuests" class="only-email">
								<div v-if="externalGuest.response" :class="`status ${externalGuest.response ? externalGuest.response : 'maybe'}`">
									<icon-check v-if="externalGuest.response == 'yes'" />
									<icon-close v-else-if="externalGuest.response == 'no'" />
									<icon-question v-else />
								</div>
								<div class="email">{{ externalGuest.email }}</div>
							</div>
						</div>
					</div>

					<div class="info-row last" v-if="meeting.status == 'not_started'">
						<div class="title-row">
							<div class="title">{{ $t("add_meeting.response_meeting") }}:</div>
							<div class="msg" v-if="showConfirmedMsg">
								{{ $t("add_meeting.updated_answer") }}
							</div>
						</div>
						<div class="buttons">
							<div class="button green" v-bind:class="{ active: response == 'yes' }" @click="postAnswer('yes')">
								{{ $t("general.yes") }}
							</div>
							<div class="button red" v-bind:class="{ active: response == 'no' }" @click="postAnswer('no')">
								{{ $t("general.no") }}
							</div>
							<div class="button yellow" v-bind:class="{ active: response == 'maybe' }" @click="postAnswer('maybe')">
								{{ $t("general.maybe") }}
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<meeting-view-loader v-else />
	</div>
</template>

<script type="text/javascript">
import MeetingViewLoader from "../PagesLoaders/MeetingView.vue";

import IconClose from "../Icons/Close";
import IconCalendar from "../Icons/Calendar";
import IconClock from "../Icons/Clock";
import IconLocation from "../Icons/Location";
import IconCheck from "../Icons/Check";
import IconQuestion from "../Icons/Question";
import IconHoliday from "../Icons/Holiday";
import IconEdit from "../Icons/Edit";

export default {
	components: {
		MeetingViewLoader,
		IconClose,
		IconCalendar,
		IconClock,
		IconLocation,
		IconCheck,
		IconQuestion,
		IconEdit,
		IconHoliday,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			loaded: false,
			optionsUsers: [],
			meetingId: "",
			meeting: {
				parentMeetingId: "",
				title: "",
				description: "",
				startHour: "",
				endHour: "",
				location: {
					physical: "",
					online: "",
				},
				guests: [],
				externalGuests: [],
				added_by: {},
				publicLink: "",
				status: "",
				can_lead: false,
			},
			response: "",
			loadingSaveMeeting: false,
			meetingError: false,
			showConfirmedMsg: false,
			parentId: "",
		};
	},
	async mounted() {
		await this.getUsers();
		await this.getMeeting();
	},
	methods: {
		async getMeeting() {
			await axios
				.get(`/meetings/${this.data.meetingEditId}/edit`)
				.then(({ data }) => {
					Object.assign(this.meeting, {
						added_by: data.data.added_by,
						parentMeetingId: data.data.id,
						title: data.data.name,
						description: data.data.description,
						startHour: data.data.start_time,
						endHour: data.data.end_time,
						location: {
							physical: data.data.location,
							online: data.data.link,
						},
						guests: this.optionsUsers
							.filter((el) =>
								data.data.users
									.map((user) => {
										return user.id;
									})
									.includes(el.id)
							)
							.map((user) => {
								return { id: user.id, name: user.name, avatar: user.avatar, response: data.data.users.find((el) => el.id == user.id).response };
							}),
						publicLink: data.data.session_link ? `${process.env.VUE_APP_ORGANIGRAMA_FE_LINK}/meeting/${data.data.session_link}` : "",
						status: data.data.status,
						can_lead: data.data.can_lead,
					});

					this.meetingId = data.data.id;
					this.meeting.externalGuests = data.data.external_invites;
					this.parentId = data.data.parent_id ? data.data.parent_id : "";

					var responseParsed = data.data.users.find((el) => el.id == this.$auth.user().id) ? data.data.users.find((el) => el.id == this.$auth.user().id) : "";
					this.response = responseParsed.response;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 404) {
							this.meetingError = true;
						}
					}
				})
				.finally(() => {
					this.loaded = true;
					setTimeout(() => {
						$(".opacity-page").addClass("show");
					}, 0);
				});
		},
		closeModal() {
			this.$emit("close_modal");
		},
		async getUsers() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, { params: { users: true } })
				.then(({ data }) => {
					this.optionsUsers = data.data.users;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.data.crud != "edit" ? (this.loaded = true) : "";
					setTimeout(() => {
						$(".opacity-page").addClass("show");
					}, 0);
				});
		},
		postAnswer(response) {
			this.response = response;
			this.loadingSaveMeeting = true;
			axios
				.post(`/meetings/${this.data.meetingEditId}/response`, { response: response, invite_type: "internal_invite" })
				.then(() => {
					this.showConfirmedMsg = true;
				})
				.finally(() => {
					setTimeout(() => {
						this.showConfirmedMsg = false;
					}, 3000);
				});
		},
		openEditMeeting() {
			this.$root.$emit("change_modal", "add_meeting", {
				crud: "edit",
				meetingEditId: this.data.meetingEditId,
				type: this.data.type,
				date: this.data.date,
			});
		},
		refreshCalendar() {
			this.$root.$emit("refreshSplitView");
			this.$root.$emit("refresh_ToDo_List");
			this.$emit("close_modal");
		},
		startMeeting() {
			var formData = {};

			if (!this.parentId) {
				formData.date = this.data.date;
			}

			axios.post(`/meeting-session/${this.meetingId}/store`, formData).then(({ data }) => {
				this.$router.push({ name: "public-meeting", params: { uuid: data.data.session_link } });
			});
		},
	},
};
</script>
