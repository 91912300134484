<template>
	<div class="modal-tbf modal-full-page modal-report-responsibility">
		<div class="container-modal opacity-page">
			<div class="header-modal">
				<div class="title">{{ $t("user-details.responsibilities") }} {{ data.name }}</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="cancelModal"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<div class="header-filter">
				<div class="row-space-tbf search-row no-padding-top">
					<div class="content">
						<div class="search-input">
							<input type="text" :placeholder="$t('general.search_by_name')" v-model="search_filter" />
						</div>
						<div class="actions">
							<button class="btn-tbf blue center" @click="donwloadXLS">
								<span class="text">{{ $t("report_users.download_xls") }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<template v-if="loadedList">
				<div class="data-list users" v-if="filteredResponsibilities.length">
					<div class="row-space-tbf header-list">
						<div class="content">
							<div class="column-filter column-user">
								<div class="text">{{ $t("general.name") }}</div>
							</div>
							<div v-if="$resize && $mq.above(700)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_responsibilities") }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_target") }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_accomplished") }}</div>
							</div>
						</div>
					</div>

					<template v-for="entry in filteredResponsibilities">
						<div class="row-space-tbf row-list-item" :key="`entryItem${entry.id}`">
							<div class="content">
								<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
								<div class="column-user" :class="[entry.progress_type ? 'column-name-desc' : 'column-name-title']">
									<div class="text">
										<div class="name">{{ entry.name }}</div>
										<div class="description" v-if="entry.progress_type">
											{{ $t("responsibility.progress_type_text." + entry.progress_type, { to: parseFloat(entry.target), unit: entry.unity }) }}
										</div>
									</div>
								</div>
								<div v-if="$resize && $mq.above(700)" class="column-simple-text column-total-sum">
									<div class="text">{{ parseFloat(parseFloat(entry.total_logs).toFixed(2)) }}</div>
								</div>
								<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
									<div class="text">{{ parseFloat(parseFloat(entry.total_target).toFixed(2)) }}</div>
								</div>
								<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum" :class="[checkClassProgress(entry)]">
									<div class="text">
										{{ entry.total_value_logs == "no_evaluations" ? $t("report_users.no_evaluations") : parseFloat(parseFloat(entry.total_value_logs).toFixed(2)) }}
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="row-space-tbf list-empty modal-empty" v-else>
					<div class="content full">
						<div class="title">{{ $t("empty.title") }}</div>
					</div>
				</div>
			</template>
			<loader-items-modal-list v-else />
		</div>
	</div>
</template>

<script>
import IconClose from "../Icons/Close";
import IconPeople from "../Icons/User";
import IconSearch from "../Icons/Search";
import IconDate from "../Icons/Date";
import IconArrow from "../Icons/Arrow";
import IconUser from "../Icons/User";
import LoaderItemsModalList from "../PagesLoaders/ItemsModalList";

export default {
	data() {
		return {
			loadedList: false,
			search_filter: "",
			entries: [],
		};
	},
	props: {
		data: Object,
	},
	components: {
		IconClose,
		IconPeople,
		IconSearch,
		IconDate,
		IconArrow,
		IconUser,
		LoaderItemsModalList,
	},
	async mounted() {
		setTimeout(() => {
			$(".opacity-page").addClass("show");
		}, 0);

		await this.getEntries();
	},
	computed: {
		filteredResponsibilities() {
			return getByKeyword(this.entries, this.search_filter);
		},
	},
	methods: {
		getEntries($state) {
			var paramsCall = { start_date: moment(this.data.start_date).format("YYYY-MM-DD"), end_date: moment(this.data.end_date).format("YYYY-MM-DD") };

			axios
				.get(`/reports/${this.data.slug}/user-responsibilities`, { params: paramsCall })
				.then(({ data }) => {
					this.entries = data.data;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loadedList = true;
				});
		},
		donwloadXLS() {
			var showColumnsObj = ["name", "progress_type", "total_logs", "total_target", "total_value_logs"];

			var paramsCall = {};
			paramsCall.type = "xls";
			paramsCall.show_columns = showColumnsObj;

			paramsCall.start_date = moment(this.data.start_date).format("YYYY-MM-DD");
			paramsCall.end_date = moment(this.data.end_date).format("YYYY-MM-DD");

			paramsCall.search = this.search_filter;

			axios
				.get("/reports/" + this.data.slug + "/user-responsibilities", {
					params: paramsCall,
					responseType: "blob",
				})
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = `${this.$t("user-details.responsibilities")} ${this.data.name}.xlsx`;

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();
				});
		},
		cancelModal() {
			this.$emit("close_modal");
		},
		checkClassProgress(data) {
			if (data.type == "check") {
				if (data.total_value_logs >= data.total_target) {
					return "green";
				}
			} else if (data.type == "value") {
				if (data.progress_type == "grow") {
					if (data.total_value_logs >= data.total_target) {
						return "green";
					}
				} else {
					if (data.total_value_logs != "no_evaluations") {
						if (data.total_value_logs <= data.total_target) {
							return "green";
						}
					} else {
						return "red";
					}
				}
			}

			return "red";
		},
	},
};

function getByKeyword(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>
