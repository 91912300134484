<template>
	<div class="modal_info choose-pipelines">
		<div class="form-section">
			<!-- add frequency -->
			<div class="input-group w-100" v-if="data.crud == 'edit'">
				<div class="label-header">
					<label class="label">{{ $t('pipelines.choose_pipeline') }}</label>
				</div>
				<div class="input-box" v-if="!data.project_id">
					<div class="icon-left"><icon-pipeline /></div>
					<multiselect 
                        class="hide-tags multiselect_checkbox"
                        v-bind:class="{populate: selectedPipeline}"
                        v-model="selectedPipeline"
                        :options="pipelines"
						open-direction="bottom"
                        :allow-empty="true"
                        :show-labels="false"
                        :multiple="false" 
                        :close-on-select="true"
                        track-by="id" 
                        label="name"
                        >
						<template slot="selection" slot-scope="{ values, search, isOpen }">
							<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
							<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
						</template>
						<template slot="option" slot-scope="props">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
							</div>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
				<div class="description" v-else>
					{{ $t('pipelines.pipeline_auto_selected')}}
				</div>
			</div>
			
            <div class="input-group" v-if="$auth.user().id == data.responsible_id"> 
                <div class="label-header">
                    <div class="label">{{ $t('general.private') }}</div>
                </div>

				<div class="item-switch">
					<div class="name">{{ $t('add_task.private_def') }}</div>
					<div class="action">
						<label class="switch">
							<input type="checkbox" value="1" v-model="privateTask">
							<span class="slider round"></span>
						</label>
					</div>
				</div>
            </div>
		</div>

		<div class="button-actions small">
			<button class="btn-tbf white center" @click="closeModal">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitDelete" class="btn-tbf blue center" @click="saveAction">
				<div class="loader-spin" v-if="loadingSave"></div>
				<div class="text" v-else>{{ $t('general.save') }}</div>
			</button>
		</div>
	</div>
</template>

<script>
    import IconArrow from '@/components/Icons/Arrow'
	import IconPipeline from '@/components/Icons/IconPipeline'

	export default {
        components:{
            IconArrow,
			IconPipeline
        },
		data() {
			return {
				loadingSave: false,
                pipelines: [],
                selectedPipeline: '',
				privateTask: false
			};
		},
		props: {
			data: Object
		},
		async mounted() {
			await this.getFilters();

			this.privateTask = this.data.is_private
		},
		methods: {
            async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {
					pipelines: true,
					pipeline_type: 'task'
				}})
				.then(({data}) => {
					this.pipelines = data.data.pipelines;

					if(this.data.pipeline_id){
						this.selectedPipeline = this.pipelines.find(el => el.id == this.data.pipeline_id);
					}
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			closeModal(){
				this.$emit("cancel_close");
			},
            saveAction(){
				if( ( !this.selectedPipeline || (this.selectedPipeline && this.selectedPipeline.id != this.data.pipeline_id) ) && this.data.crud == 'edit') { 
					this.updatePipeline();
				}

				if(this.privateTask != this.data.is_private) {
					this.updateTask();
				}
            },
			updatePipeline() {
				var objectData = {
					pipeline_id: this.selectedPipeline ? this.selectedPipeline.id : null,
				}
	
				if(!this.selectedPipeline) {
					objectData.stage_id = null;
				}
	
				var stageId = '';
				var pipelineId = '';
	
				axios.post(`/tasks/${this.data.id}/update-pipeline`, objectData)
				.then(({data}) => {
					stageId = data.data.stage_id;
					pipelineId = data.data.pipeline_id;
					
					this.$parent.pipeline_id = pipelineId;
					this.$parent.stage_id = stageId;
				})
				.finally(() => {
					this.closeModal();
				})
			},
			updateTask() {
				this.$parent.is_private = this.privateTask;

				this.closeModal();
			}
		}
	};
</script>