<template>
	<div class="modal_info confirm-close">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/warning.svg"></div>
			<div class="title">{{$t('confirm-close.title')}}</div>
		</div>
		<div class="description-modal">{{$t('confirm-change-step.desc')}}</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="closeModal('not_save')">
				<div class="text">{{ $t('confirm-change-step.not-save') }}</div>
			</button>
			<button class="btn-tbf blue center" @click="closeModal('save')">
				<div class="loader"></div>
				<div class="text">{{ $t('confirm-change-step.save') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {

			};
		},
        props: {
            data: Object
        },
		async mounted() {
			console.log(this.data)
		},
		methods: {
			closeModal(modeSave){
                this.$root.$emit('user_form_change_step', modeSave, this.data )

				this.$emit("close_modal");
			},
		}
	};
</script>