<template>
    <div class="history-content">
        <div class="list-updates" v-if="loadedList">
            <div class="update-item" v-for="updateLog in updates" v-if="checkVisibilityOfTaskParentLog(updateLog)">
                <div class="header-data">
                    <div class="avatar" v-if="updateLog.user">
                        <img :src="updateLog.user.avatar" />
                    </div>
                    <div class="user-data">
                        <div class="name" v-if="updateLog.user">{{ updateLog.user.name }}</div>
                    </div>
                    <div class="left-content">
                        <div class="only-log" v-if="updateLog.model_type == 'task_log'">{{ $t('add_task.history_only_log') }}</div>
                        <div class="time_update">{{ updateLog.date | moment('DD MMM YYYY hh:mm') }}</div>
                    </div>
                </div>

                <div class="content-update">
                    <div class="column-update" v-for="(modification, key) in updateLog.modifications" v-if="modification.old != modification.new && !['date'].includes(modification.key)">
                        <div class="header" v-if="['name', 'description'].includes(modification.key)">
                            <div class="name">{{ $t(`add_task.database_keys.${modification.key}`) }}</div>
                            <div class="arrow-view-more" :class="{active: activeViewMores.find(el => el.column == modification.key && el.update_id == key)}" @click="handleActiveMore(modification.key, key)"><icon-arrow /></div>
                        </div>
                        <div class="modification" :class="{active: activeViewMores.find(el => el.column == modification.key && el.update_id == key)}" v-html="generateCompareHtml(modification.old, modification.new)" v-if="['name', 'description'].includes(modification.key)"></div>

                        <div class="modification" :class="{active: activeViewMores.find(el => el.column == modification.key && el.update_id == key)}" v-else>
                            <div class="text" v-html="generateSimpleText(modification, updateLog.modifications)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loader-history v-else />
    </div>
</template>

<script>
    import IconArrow from '@/components/Icons/Arrow';
    import IconArrowLong from '@/components/Icons/ArrowLong';
    import LoaderHistory from './LoaderHistory';
	import InfiniteLoading from 'vue-infinite-loading'

	export default {
        components: {
            InfiniteLoading,
            IconArrow,
            IconArrowLong,
            LoaderHistory
        }, 
        props: {
            taskType: String,
            taskId: Number,
            dateTask: String
        },
        data() {
            return {
                updates: [],
                activeViewMores: [],
                loadedList: false
            }
        },
        mounted() {
            this.getUpdates();
        },
        methods: {
            getUpdates(){
                var paramsCall = { type: 'task', entity_id: this.taskId, date: this.dateTask }

        		axios.get(`/${this.$auth.user().instance.id}/history/model-changes`, {params: paramsCall})
        		.then(({data}) => {
        			if(data.data.length){
        				this.updates = data.data;
					}
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
                .finally(() => {
					this.loadedList = true
                })
            },
            generateCompareHtml(oldValue, newValue){
                return diff(oldValue ? oldValue : '', newValue ? newValue : '', "highlighted")
            },
            handleActiveMore(column, updateId) {
                var itemFindIndex = this.activeViewMores.findIndex((el) => el.column == column && el.update_id == updateId );
                if(itemFindIndex >= 0) {
                    this.activeViewMores.splice(itemFindIndex, 1);
                } else {
                    this.activeViewMores.push({column: column, update_id: updateId});
                }
            },
            generateSimpleText(log, allModifications) {
                var oldValue = log.old;
                var newValue = log.new;

                if(['date', 'start_date', 'deadline'].includes(log.key)) {
                    oldValue = oldValue ? this.$options.filters.moment(oldValue, 'DD MMMM YYYY') : '';
                    newValue = newValue ? this.$options.filters.moment(newValue, 'DD MMMM YYYY') : '';
                } else if (log.key == 'measurement') {
                    var frequency = allModifications.find(el => el.key == 'frequency');

                    if(frequency.old == 'weekly') {
                        oldValue = oldValue && oldValue.length ? oldValue.map((a) => { return this.$t(`calendar.long_days.${a}`) }).join(", ") : '';
                    } else if (frequency.old == 'monthly') {
                        oldValue = oldValue && oldValue.length ? oldValue.join(", ") : '';
                    } else if (frequency.old == 'monthly_dynamically') {
                        oldValue = oldValue && oldValue.length ? oldValue.map((a) => { return this.$t(`calendar.number_days.${a}`) }).join(", ").replace(/, ([^,]*)$/, ` ${this.$t('general.and')} $1`) : '';
                    } else if (frequency.old == 'yearly') {
                        oldValue = oldValue && oldValue.length ? oldValue.map((a) => { return moment(a, 'MM-DD').format('DD.MM') }).join(", ") : '';
                    }

                    if(frequency.new == 'weekly') {
                        newValue = newValue && newValue.length ? newValue.map((a) => { return this.$t(`calendar.long_days.${a}`) }).join(", ") : '';
                    } else if (frequency.new == 'monthly') {
                        newValue = newValue && newValue.length ? newValue.join(", ") : '';
                    } else if (frequency.new == 'monthly_dynamically') {
                        newValue = newValue && newValue.length ? newValue.map((a) => { return this.$t(`calendar.number_days.${a}`) }).join(", ").replace(/, ([^,]*)$/, ` ${this.$t('general.and')} $1`) : '';
                    } else if (frequency.new == 'yearly') {
                        newValue = newValue && newValue.length ? newValue.map((a) => { return moment(a, 'MM-DD').format('DD.MM') }).join(", ") : '';
                    }

                } else if (log.key == 'measurement_dynamically') {
                    oldValue = oldValue && oldValue.length ? oldValue.map((a) => { return this.$t(`calendar.weeks.${a}`) }).join(", ").replace(/, ([^,]*)$/, ` ${this.$t('general.and')} $1`) : '';
                    newValue = newValue && newValue.length ? newValue.map((a) => { return this.$t(`calendar.weeks.${a}`) }).join(", ").replace(/, ([^,]*)$/, ` ${this.$t('general.and')} $1`) : '';
                } else if (log.key == 'frequency') {
                    oldValue = oldValue ? this.$t(`responsibility.frequencies.${oldValue}`) : '';
                    newValue = newValue ? this.$t(`responsibility.frequencies.${newValue}`) : '';
                } else if (log.key == 'priority') {
                    oldValue = oldValue ? this.$t(`dashboard.priority.${oldValue}`) : '';
                    newValue = newValue ? this.$t(`dashboard.priority.${newValue}`) : '';
                } else if (log.key == 'done') {
                    oldValue = oldValue ? this.$t(`add_task.history_done_status.${oldValue}`) : '';
                    newValue = newValue ? this.$t(`add_task.history_done_status.${newValue}`) : '';
                }
                

                if (log.key == 'evaluator_id') {
                    return this.$t('add_task.history_changes.evaluated', { type: this.$t(`add_task.history_log_keys.${log.key}`), new: newValue})
                }

                if (log.key == 'done_before_deadline') {
                    return this.$t('add_task.history_changes.done_before_deadline', { type: this.$t(`add_task.done_before_deadline_status.${log.new}`) })
                }

                if(oldValue && newValue) {
                    return this.$t('add_task.history_changes.changed', { type: this.$t(`add_task.history_log_keys.${log.key}`), old: oldValue, new: newValue })
                } else if(oldValue && !newValue) {
                    return this.$t('add_task.history_changes.removed', { type: this.$t(`add_task.history_log_keys.${log.key}`), old: oldValue})
                } else if(!oldValue && newValue) {
                    if(log.key == 'project_id') { 
                        return this.$t('add_task.history_changes.linked', { type: this.$t(`add_task.history_log_keys.${log.key}`), new: newValue})
                    } else {
                        return this.$t('add_task.history_changes.added', { type: this.$t(`add_task.history_log_keys.${log.key}`), new: newValue})
                    }
                }
                
                return '';
            },
            checkVisibilityOfTaskParentLog(log) {
                var findKeyDate = log.modifications.find(el => el.key == 'date');
                
                if(findKeyDate) {
                    // this.dateTask = date la task log
                    if(log.model_type == 'task' && moment(findKeyDate.old).startOf('day').diff(moment(this.dateTask, 'YYYY-MM-DD').startOf('day'), 'days') <= 0 ) { 
                        return true;
                    } else {
                        return false;
                    }
                }

                return true;
            }
        }
        
    }
</script>