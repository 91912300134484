import Vue       from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../components/${view}.vue`);
}

const routes = [
	{ path: '/login', name: 'login', component: loadView('Auth/Login'), meta: { auth: false, layout: 'login-template'}},
	// { path: '/register', name: 'register', component: loadView('Auth/Register'), meta: { auth: false, layout: 'login-template'}},
	
	{ path: '/password/forgot', name: 'password-forgot', component: loadView('Auth/ForgotPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/password/forgot-success', name: 'password-forgot-success', component: loadView('Auth/ForgotPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password/:token', name: 'password-reset', component: loadView('Auth/ResetPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password-success', name: 'password-reset-success', component: loadView('Auth/ResetPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/register-success', name: 'register-success', component: loadView('Auth/RegisterSuccess'), meta: { auth: true, layout: 'login-template'}},
	
	{ path: '/payment', name: 'payment', component: loadView('Auth/Register'), meta: { auth: true, layout: 'login-template' }},

	{ path: '/', name: 'dashboard', component: loadView('Pages/Dashboard/Index'), meta: { auth: true, layout: 'main-template'}},
	{ path: '/dashboard', name: 'dashboard_user', component: loadView('Pages/Dashboard/Index'), meta: { auth: true, layout: 'main-template'}},

	{ path: '/company', name: 'company', component: loadView('Pages/Company/Organigram'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-template' } },
	{ path: '/company/department/:slug', name: 'department', component: loadView('Pages/Company/Organigram'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-template' }},

	// Users
	{ path: '/users', name: 'users', component: loadView('Pages/Users/Index'), meta: { auth: true, layout: 'main-template'}},
	{ path: '/users/create', name: 'user-create', component: loadView('Pages/Users/NewCreate/Index'), meta: { auth: true, layout: 'form-template'}},
	{ path: '/users/edit/:slug', name: 'user-edit', component: loadView('Pages/Users/NewCreate/Index'), meta: { auth: true, layout: 'form-template'}},
	{ path: '/user/:slug', name: 'user-show', component: loadView('Pages/Users/Show'), meta: { auth: true, layout: 'main-template' } },

	{ path: '/users/evaluation', name: 'user-evaluation', component: loadView('Pages/UserDetails/Evaluation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/edit/evaluation/:id', name: 'user-edit-evaluation', component: loadView('Pages/UserDetails/Evaluation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/warning', name: 'user-warning', component: loadView('Pages/UserDetails/Warning'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/edit/warning/:id', name: 'user-edit-warning', component: loadView('Pages/UserDetails/Warning'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/congratulation', name: 'user-congratulation', component: loadView('Pages/UserDetails/Congratulation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/edit/congratulation/:id', name: 'user-edit-congratulation', component: loadView('Pages/UserDetails/Congratulation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/holidays', name: 'holidays', component: loadView('Pages/Settings/Holidays'), meta: { auth: true, layout: 'main-template'}},

	// Roles
	{ path: '/roles', name: 'roles', component: loadView('Pages/Roles/Index'), meta: { auth: true, layout: 'main-template'}},
	{ path: '/roles/create', name: 'roles-create', component: loadView('Pages/Roles/Create'), meta: { auth: true, layout: 'form-template'}},
	{ path: '/roles/edit/:id', name: 'roles-edit', component: loadView('Pages/Roles/Create'), meta: { auth: true, layout: 'form-template' } },
	{ path: '/roles/:id', name: 'roles-show', component: loadView('Pages/Roles/Show'), meta: { auth: true, layout: 'main-template'}},

	{ path: '/settings/subscription', name: 'settings-subscriptions', component: loadView('Pages/Settings/Subscriptions'), meta: { auth: true, layout: 'settings-template'}},
	{ path: '/settings/work-schedules', name: 'settings-work-schedules', component: loadView('Pages/Settings/WorkSchedules'), meta: { auth: true, layout: 'settings-template'}},
	{ path: '/settings/departments', name: 'settings-departments', component: loadView('Pages/Settings/Departments'), meta: { auth: true, layout: 'settings-template'}},
	{ path: '/settings/workstations', name: 'settings-workstations', component: loadView('Pages/Settings/Workstations'), meta: { auth: true, layout: 'settings-template'}},
	{ path: '/settings/skills', name: 'settings-skills', component: loadView('Pages/Settings/Skills'), meta: { auth: true, layout: 'settings-template' } },
	{ path: '/settings/company', name: 'settings-company', component: loadView('Pages/Settings/Company'), meta: { auth: true, layout: 'settings-template' } },
	
	{ path: '/settings/profile', name: 'settings-profile', component: loadView('Pages/Settings/Profile'), meta: { auth: true, layout: 'settings-template'}},
	{ path: '/settings/pipeline', name: 'settings-pipeline', component: loadView('Pages/Settings/Pipelines'), meta: { auth: true, layout: 'settings-template'}},
    
	{ path: '/reports', name: 'reports', component: loadView('Pages/Reports/Index'), meta: { auth: true, layout: 'main-template' } },
	
	// Vacant Jobs
	{ path: '/vacant-jobs', name: 'vacant-jobs', component: loadView('Pages/VacantJobs/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	{ path: '/vacant-jobs/create', name: 'vacant-jobs-create', component: loadView('Pages/VacantJobs/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/vacant-jobs/edit/:slug', name: 'vacant-jobs-edit', component: loadView('Pages/VacantJobs/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/vacant-jobs/:slug', name: 'vacant-jobs-show', component: loadView('Pages/VacantJobs/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	{ path: '/vacant-job-announcement/:id', name: 'vacant-job-announcement', component: loadView('Pages/VacantJobs/CreateAd'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	
	{ path: '/software-updates', name: 'software-updates', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/software-updates/:id', name: 'software-updates-show', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/work-in-progress', name: 'work-in-progress', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/work-in-progress/:id', name: 'work-in-progress-show', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},

	// Announcements
	{ path: '/announcements', name: 'announcements', component: loadView('Pages/Announcements/Show'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/announcements/create', name: 'announcements-create', component: loadView('Pages/Announcements/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/announcements/edit/:id', name: 'announcements-edit', component: loadView('Pages/Announcements/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/announcements/:id', name: 'announcements-show', component: loadView('Pages/Announcements/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'system-template'}},

	// Projects
	{ path: '/projects', name: 'projects', component: loadView('Pages/Projects/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},
	{ path: '/projects/edit/:slug', name: 'projects-edit', component: loadView('Pages/Projects/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},
	{ path: '/projects/:slug', name: 'projects-show', component: loadView('Pages/Projects/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },

	{ path: '/meeting/:uuid', name: 'public-meeting', component: loadView('Pages/Public/Meeting/Index'), meta: { auth: undefined, layout: 'full-template' } },

	{ path: '/public/:uuid', name: 'public_company', component: loadView('Pages/Company/PublicOrganigram'), meta: { auth: false, layout: 'full-template' } },
	{ path: '/public/:uuid/department/:slug', name: 'public_department', component: loadView('Pages/Company/PublicOrganigram'), meta: { auth: false, layout: 'full-template' } },

	{ path: '/403', name: 'forbbiden', component: loadView('Pages/Forbbiden'), meta: { auth: undefined, layout: 'main-template' } },
	{ path: '/404', name: 'not-found', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'main-template' } },

	{ path: '/response-meeting/:uuid/:id', name: 'public-response-meeting', component: loadView('Pages/PublicResponseMeeting'), meta: { auth: undefined, layout: 'full-template' } },

	{ path: '*', name: 'not-exist', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'main-template' } }
]

Vue.router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
	linkActiveClass: 'active-link'
})

export default Vue.router;