<template>
    <div class="modal-tbf modal-full-page modal-crud-responsibility">
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">
                    {{ data.crud == 'edit' ? $t('responsibility.edit_title') : $t('responsibility.create_title') }}
                </div>
                <div class="actions">
					<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon " :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
                    	 <div class="input-group w-special">
                            <div class="label-header">
                                <label class="label">{{$t('roles.responsibility_name')}}</label>
                                <div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                            </div>
                            <div class="input-box bg-white department" v-bind:class="{has_error: $v.name.$error}">
                                <div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
                                <input type="text" :placeholder="$t('roles.responsibiliti-ph')" class="input-text no-icon" v-model="name">
                            </div>
                        </div>

						<div class="input-group width-fix-80">
							<div class="label-header" >
								<label class="label">{{ $t('departments.choose_color') }}</label>
							</div>
							<div class="input-box only-circle">
								<div class="form__input">
									<v-swatches
										v-model="color_code"
										popover-x="left"
										swatches="text-advanced"
									></v-swatches>
								</div>
							</div>
						</div>

						<div class="input-group w-50 checkboxes-group" :class="{disabled: !cand_edit_measure}">
                        	<label class="checkbox-tbf">
                        		<span class="text-checkbox">{{ $t('responsibility.measurable_checkbox') }}</span>
                        		<input type="checkbox" v-model="measurable" @input="resetInputs" :disabled="!cand_edit_measure">
                        		<span class="checkmark"></span>
                        	</label>
                        </div>
						<div class="input-group w-50 checkboxes-group" :class="{disabled: !measurable || !cand_edit_measure}">
							<label class="checkbox-tbf">
                        		<span class="text-checkbox">{{ $t('responsibility.requires_proof_checkbox') }}</span>
                        		<input type="checkbox" v-model="uploadFile" :disabled="!measurable || !cand_edit_measure">
                        		<span class="checkmark"></span>
                        	</label>
						</div>
						
                        <div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50', { disabled: !measurable || !cand_edit_measure} ]" v-if="measurable">
                        	<div class="label-header">
                        		<label class="label">{{ $t('responsibility.frequency') }}</label>
                                <div v-if="$v.frequency.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        	</div>
                        	<div class="input-box bg-white" v-bind:class="{has_error: $v.frequency.$error}">
                        		<div class="icon-left"><icon-repeat /></div>
                        		<multiselect 
                        		v-model="frequency"
                        		v-bind:class="{populate: frequency}"
                        		:options="optionsFrequency"
                        		:allow-empty="true"
                        		:show-labels="false"
                        		:disabled="!measurable || !cand_edit_measure"
                        		>
	                        		<template slot="placeholder" slot-scope="props">
	                        			<span class="text">
	                        				{{$t('responsibility.choose-frequency')}}
	                        			</span>
	                        		</template>
	                        		<template slot="singleLabel" slot-scope="props">{{ $t(`responsibility.frequencies.${props.option}`) }}</template>
	                        		<template slot="option" slot-scope="props">{{ $t(`responsibility.frequencies.${props.option}`) }}</template>
	                        		<template slot="noResult">{{$t('general.no_results')}}</template>
	                        		<template slot="noOptions">{{$t('general.empty_list')}}</template>
	                        	</multiselect>
	                        </div>
                        </div>

                        <div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50', { disabled: !cand_edit_measure || !measurable } ]" v-if="measurable">
                            <div class="label-header">
                                <label class="label">{{$t('responsibility.type_evaluation_label')}}</label>
                                <div v-if="$v.type_evaluation.$error" class="error-msg">{{ $t('validator.required') }}</div>
                            </div>
                            <div class="input-box-with-dropdown">
                            	<div class="input-box box-select small-padd">
                            		<multiselect 
                            		v-model="type_evaluation"
                            		:options="optionsTypeProgress"
                            		:allow-empty="false"
                            		:show-labels="false"
                            		:placeholder="$t('general.choose')"
                            		:disabled="!cand_edit_measure || !measurable"
                            		>
	                            		<template slot="singleLabel" slot-scope="props">{{ $t(`responsibility.type_evaluation.${props.option}`) }}</template>
	                            		<template slot="option" slot-scope="props">{{ $t(`responsibility.type_evaluation.${props.option}`) }}</template>
	                            		<template slot="noResult">{{$t('general.no_results')}}</template>
	                            		<template slot="noOptions">{{$t('general.empty_list')}}</template>
	                            	</multiselect>
	                            </div>

	                            <div class="input-box value-type" v-if="type_evaluation == 'check'">
	                            	<div class="icon-left"><icon-circle-check /></div>
	                            	<div class="input-text-simple">{{$t('responsibility.type_check_option')}}</div>
	                            </div>
	                            <div class="input-box value-type" v-else-if="type_evaluation == 'value'" v-bind:class="{has_error: $v.progressData.$error}">
	                            	<div class="icon-left"><icon-progress /></div>
	                            	<div class="input-text-link" @click="cand_edit_measure && measurable ? openSubModal('progress_type') : ''">
	                            		<div v-if="progressData.hasOwnProperty('unity')">{{ $t('responsibility.progress_type_text.' + progressData.type, { to: progressData.to.toString(), unit: progressData.unity}  ) }}</div>
	                            		<div v-else class="placeholder">
	                            			{{$t('responsibility.choose-progress')}}
	                            		</div>
	                            	</div>
	                            </div>
                            </div>
                        </div>

						<div class="input-group" :class="$resize && $mq.below(600) ? 'w-100' : 'w-50'" v-if="!measurable">
							<div class="label-header">
                                <label class="label">{{$t('responsibility.trigger')}}</label>
                            </div>
							<div class="input-box bg-white department">
                                <div class="icon-right" v-if="trigger != ''" @click.stop="trigger = ''"><icon-close class="icon-clear" /></div>
                                <input type="text" :placeholder="$t('responsibility.trigger_ph')" class="input-text no-icon" v-model="trigger">
                            </div>
						</div>

                        <template v-if="frequency == 'yearly'">
                        	<div class="input-group w-100" :class="{disabled: !cand_edit_measure}">
                        		<div class="label-header">
                        			<label class="label">{{ $t('responsibility.select_date') }}</label>
                        			<div v-if="$v.yearlyDays.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        		</div>
                        		<div class="input-box bg-white-all-time">
                        			<FunctionalCalendar
                        			ref="CalendarDateOfEmployment"
                        			v-model="dateOfYear"
                        			class="calendar-tbf frequency"
                        			:is-multiple-date-picker="true"
                        			:is-multiple="true"
                        			:calendars-count="3"
                        			:is-separately="false"
                        			:change-month-step="3"
                        			:hidden-elements="['leftAndRightDays']"
                        			:limits="limitsRange"
                        			:newCurrentDate="newStartDay"
                        			@dayClicked="selectDay($event, 'yearly')"
                        			>
	                        			<template v-slot:datePickerInput="props">
	                        				<input
	                        				class="vfc-single-input custom-input-picker"
	                        				type="text"
	                        				:value="props.selectedDate ? moment(props.selectedDate, 'YYYY-M-D').format('DD MMMM') : ''" 
	                        				v-if="Object.keys(dateOfYear).length"/>

	                        				<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-of-employment') }}</div>
	                        			</template>
	                        		</FunctionalCalendar>
	                        	</div>
	                        </div>
	                    </template>

                        <div class="details-date" :class="{disabled: !cand_edit_measure}">
	                        <div class="measurement" v-if="['weekly', 'monthly'].includes(frequency)">
								<template v-if="frequency == 'monthly'">
									<div class="input-group w-100">
										<div class="label-header">
											<label class="label">{{$t('responsibility.type_monthly_label')}}</label>
											<div v-if="$v.monthlyTypeSequenceDay.$error" class="error-msg">{{ $t('validator.required') }}</div>
										</div>
										<div class="input-box-with-dropdown">
											<div class="input-box box-select small-padd">
												<multiselect 
												v-model="subTypeMonthly"
												:options="optionsMonthlyTypes"
												:allow-empty="false"
												:show-labels="false"
												:placeholder="$t('general.choose')"
												:disabled="!cand_edit_measure"
												>
													<template slot="singleLabel" slot-scope="props">{{ $t(`responsibility.type_monthly.${props.option}`) }}</template>
													<template slot="option" slot-scope="props">{{ $t(`responsibility.type_monthly.${props.option}`) }}</template>
													<template slot="noResult">{{$t('general.no_results')}}</template>
													<template slot="noOptions">{{$t('general.empty_list')}}</template>
												</multiselect>
											</div>

											<div class="input-box value-type" v-if="subTypeMonthly == 'day_numbers'">
												<div class="icon-left"><icon-circle-check /></div>
												<div class="input-text-simple">{{$t('responsibility.type_check_day_numbers')}}</div>
											</div>
											<div class="input-box value-type" v-bind:class="{has_error: $v.monthlyTypeSequenceDay.$error}" v-else-if="subTypeMonthly == 'sequence_days'">
												<div class="icon-left"><icon-repeat /></div>
												<multiselect 
												class="hide-tags multiselect_checkbox"
												v-model="monthlyTypeSequenceDay"
												v-bind:class="{populate: monthlyTypeSequenceDay}"
												:options="optionsMonthlyTypeSequenceDays"
												:allow-empty="true"
												:show-labels="false"
												:close-on-select="false"
												:multiple="true"
												track-by="value" 
												label="name"
												:disabled="!cand_edit_measure"
												>
													<template slot="placeholder" slot-scope="props">
														<span class="text">
															{{$t('responsibility.choose_monthly_sequence_days')}}
														</span>
													</template>
													<template slot="selection" slot-scope="{ values, search, isOpen }">
														<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ $t(`responsibility.monthly_sequence_days_option.${values[0].name}`) }}</span>
														<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
													</template>
													<template slot="option" slot-scope="props">
														<div class="checkbox">
															<div class="checkmark"></div><span class="text">{{ $t(`responsibility.monthly_sequence_days_option.${props.option.name}`) }}</span>
														</div>
													</template>
													<template slot="noResult">{{$t('general.no_results')}}</template>
													<template slot="noOptions">{{$t('general.empty_list')}}</template>
												</multiselect>
											</div>
										</div>
									</div>
								</template>

	                        	<template v-if="frequency == 'weekly' || frequency == 'monthly' && subTypeMonthly == 'sequence_days'">
	                        		<div class="input-group w-100">
	                        			<div class="label-header">
	                        				<label class="label">{{$t('responsibility.select_weekly_days')}}</label>
	                        				<div v-if="$v.weeklyDays.$error" class="error-msg">{{ $t('validator.required') }}</div>
	                        			</div>
	                        			<div class="input-select-days" v-bind:class="{has_error: $v.weeklyDays.$error}">
	                        				<div class="select-days weekly">
	                        					<div class="checkbox-days" v-for="day in optionsWeeklyDays">
	                        						<span @click="selectDay(day, 'weekly')" class="checkmark" v-bind:class="{active: weeklyDays.includes(day)}">{{ $t(`calendar.small_days.${day.name}`) }}</span>
	                        					</div>
	                        				</div>
	                        			</div>
	                        		</div>
	                        	</template>

	                        	<template v-else-if="frequency == 'monthly'">
	                        		<div class="input-group w-100">
	                        			<div class="label-header">
	                        				<label class="label">{{$t('responsibility.select_monthly_days')}}</label>
	                        				<div v-if="$v.monthlyDays.$error" class="error-msg">{{ $t('validator.required') }}</div>
	                        			</div>
	                        			<div class="input-select-days" v-bind:class="{has_error: $v.monthlyDays.$error}">
	                        				<div class="select-days">
	                        					<div class="checkbox-days" v-for="day in optionsMonthlyDays">
	                        						<span @click="selectDay(day, 'monthly')" class="checkmark" v-bind:class="{active: monthlyDays.includes(day)}">{{ day }}</span>
	                        					</div>
	                        				</div>
	                        			</div>
	                        		</div>
	                        	</template>
	                        </div>

	                        <div class="time-section" v-if="frequency">
	                        	<div class="input-group w-100 checkboxes-group">
	                        		<label class="checkbox-tbf">
	                        			<span class="text-checkbox">{{ $t('responsibility.all_day') }}</span>
	                        			<input type="checkbox" v-model="allDay" :disabled="!cand_edit_measure">
	                        			<span class="checkmark"></span>
	                        		</label>						
	                        	</div>
	                        	<div class="input-group-hours w-100" v-if="!allDay">
	                        		<div class="input-group w-50">
	                        			<div class="label-header">
	                        				<label class="label">{{$t('responsibility.start_hour')}}</label>
	                        			</div>
	                        			<div class="input-box bg-white department" v-bind:class="{has_error: $v.startHour.$error || !isCorrectDate}">
	                        				<div class="icon-left"><icon-clock /></div>
											<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="5" v-model="startHour" key="timepicker-start" :disabled="!cand_edit_measure"/>
	                        			</div>
	                        		</div>
	                        		<div class="input-group w-50">
	                        			<div class="label-header">
	                        				<label class="label">{{$t('responsibility.end_hour')}}</label>
	                        			</div>
	                        			<div class="input-box bg-white department" v-bind:class="{has_error: $v.endHour.$error || !isCorrectDate}">
	                        				<div class="icon-left"><icon-clock /></div>
											<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="5" v-model="endHour" key="timepicker-end" :disabled="!cand_edit_measure"/>
	                        			</div>
	                        		</div>
	                        	</div>
	                        </div>

	                         <div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50' ]" v-if="frequency == 'yearly' && yearlyDays.length">
	                        	<div class="label-header">
	                        		<label class="label">{{ $t('responsibility.selected_dates') }}</label>
	                        	</div>
	                        	<div class="input-box selected-dates-yearly" v-bind:class="{has_error: $v.yearlyDays.$error}">
	                        		<div class="date" v-for="date in yearlyDays">
	                        			<span>{{ moment(date, 'MM-DD').format('DD MMMM') }}</span>
	                        			<button @click="selectDay(date, 'yearly', true)"><icon-close /></button>
	                        		</div>
	                        	</div>
	                        </div>
                        </div>

                        <div class="input-group w-100">
                        	<div class="label-header">
                                <label class="label">{{$t('responsibility.description')}}</label>
                            </div>
                            <div class="input-box bg-white">
                                <quill-editor class="editor-tbf h-150" ref="myQuillEditor" v-model="description" :options="editorOption" />
                            </div>
                        </div>

						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
                        	<div class="label-header">
                        		<label class="label">{{ $t('responsibility.procedures_linked') }}</label>
                        	</div>
                        	<div class="input-box bg-white">
                        		<div class="icon-left"><icon-repeat /></div>
                        		<multiselect 
									class="hide-tags multiselect_checkbox"
									v-model="selectedProcedures"
									v-bind:class="{populate: selectedProcedures}"
									:options="optionsProcedures"
									:allow-empty="true"
									:show-labels="false"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									track-by="id" 
									label="name"
                        		>
	                        		<template slot="placeholder" slot-scope="props">
	                        			<span class="text">
	                        				{{$t('responsibility.choose_procedures')}}
	                        			</span>
	                        		</template>
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
										<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
									</template>
									<template slot="option" slot-scope="props">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
										</div>
									</template>
	                        		<template slot="noResult">{{$t('general.no_results')}}</template>
	                        		<template slot="noOptions">{{$t('general.empty_list')}}</template>
	                        	</multiselect>
	                        </div>
                        </div>
						<div class="input-group checkboxes-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']" v-if="selectedProcedures.length">
                        	<label class="checkbox-tbf">
                        		<span class="text-checkbox">{{ $t('responsibility.required_procedures') }}</span>
                        		<input type="checkbox" v-model="required_run_procedure">
                        		<span class="checkmark"></span>
                        	</label>
                        </div>
                    </div>

                    <div class="form-submit department">
                        <button class="btn-tbf grey center" @click="closeModal">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.cancel')}}</div>
                        </button>
                        
                        <div class="error-msg" v-if="error_message != ''">{{error_message}}</div>
                        <div class="error-msg" v-if="!isCorrectDate">{{ $t('responsibility.error_end_date_before_start') }}</div>

                        <button id='buttonCreateDepartment' class="btn-tbf blue center" @click="data.crud == 'create' ? saveAction('create') : saveAction('update')">
                            <div class="loader"></div>
                            <div class="text">{{ data.crud == 'create' ? $t('general.add') : $t('general.update')}}</div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
                <div class="overlay-submodal"></div>
                <modal-confirm-close id="submodal_confirm_close" v-if="submodal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="closeSubModal('confirm_close')" />
                <modal-progress-type id="submodal_progress_type" v-if="submodal.progress_type" :progressData="JSON.stringify(progressData)" @submitAction="getProgressData" @close_modal="closeSubModal('progress_type')" />
            </div>
    	</div>
        <department-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconRepeat from '../Icons/Repeat'
	import IconCalendar from '../Icons/Calendar'
	import IconClock from '../Icons/Clock'
	import IconProgress from '../Icons/Progress'
	import IconCircleCheck from '../Icons/CircleCheck'
	import ModalConfirmClose from '../Modals/ConfirmClose'
	import ModalProgressType from '../Modals/SubmodalProgressType'
	import IconQuestion from '../Icons/Question'

	import VSwatches from 'vue-swatches'
	import 'vue-swatches/dist/vue-swatches.css'

	import { required, requiredIf, helpers } from 'vuelidate/lib/validators'
	import DepartmentLoaderModal from '../PagesLoaders/DepartmentModal.vue'
	import Quill from 'quill'

	const checkTime = (value) => {
		if(typeof value === 'string') {
			if(!value.includes('HH') && !value.includes('mm') ) { return true; }
		} else {
			if(value.HH != 'HH' && value.mm != 'mm') { return true; }
		}
		return false;
	}

	export default {
		data() {
			return {
                loaded: false,
                subModalShow: false,
                submodal: {
                	confirm_close: false,
                	progress_type: false,
                },
                error_message: '',
                cand_edit_measure: true,
                name: '',
                description: '',
                measurable: true,
				uploadFile: false,
                frequency: '',
                optionsFrequency: [ 'daily', 'weekly', 'monthly', 'yearly'],
				subTypeMonthly: 'day_numbers',
				optionsMonthlyTypes: ['day_numbers', 'sequence_days'],
				monthlyTypeSequenceDay: [],
				optionsMonthlyTypeSequenceDays: [{name: 'first', value: 0}, {name: 'second', value: 1}, {name: 'third', value: 2}, {name: 'fourth', value: 3}, {name: 'last', value: 4}],
                allDay: true,
                startHour: '',
                endHour: '',
                weeklyDays: [],
                optionsWeeklyDays: [{name: 'monday', value: 1}, {name: 'tuesday', value: 2}, {name: 'wednesday', value: 3}, {name: 'thursday', value: 4}, {name: 'friday', value: 5}, {name: 'saturday', value: 6}, {name: 'sunday', value: 7} ],
                monthlyDays: [],
				optionsMonthlyDays: Array.from({length: 31}, (_, i) => i + 1),
				dateOfYear: {},
                yearlyDays: [],
				limitsRange: {min: '', max: ''},
				newStartDay: '',
                progressData: {},
                editorOption: {
					modules:{
						toolbar: [
							[{ 'header': [ 3, false] }],
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
							[{ 'color': [] }, { 'background': [] }],
							['link'],
							['clean']
						]
					}
				},
				optionsTypeProgress: ['check', 'value'],
				type_evaluation: 'check',
				isCorrectDate: true,
				helpData: this.$store.getters['help_links/currentLink']('modal_responsibility'),
				color_code: '',
				optionsProcedures: [],
				selectedProcedures: [],
				required_run_procedure: false,
				trigger: ''
			};
		},
        components: {
            IconClose,
            IconRepeat,
            IconCalendar,
            IconClock,
            IconProgress,
            IconCircleCheck,
            ModalConfirmClose,
            ModalProgressType,
            DepartmentLoaderModal,
			IconQuestion,
			VSwatches
        },
        props: {
            data: Object
        },
        validations: {
			name: {required},
			frequency: {
				required: requiredIf( function() { return this.measurable } )
			},
			weeklyDays: { 
				required: requiredIf( function() { return this.frequency == 'weekly' || (this.frequency == 'monthly' && this.subTypeMonthly == 'sequence_days') } )
			},
			monthlyDays: { 
				required: requiredIf( function() { return this.frequency == 'monthly' && this.subTypeMonthly == 'day_numbers' } )
			},
			yearlyDays: {
				required: requiredIf( function() { return this.frequency == 'yearly' } )
			},
			startHour: {
				required: requiredIf( function() { return !this.allDay && this.measurable } ),
				checkTime
			},
			endHour: {
				required: requiredIf( function() { return !this.allDay && this.measurable } ),
				checkTime
			},
			type_evaluation: {
				required: requiredIf( function() { return this.measurable } )
			},
			progressData: {
				required: requiredIf( function() { return this.type_evaluation == 'value' } )
			},
			monthlyTypeSequenceDay: {
				required: requiredIf( function() { return this.frequency == 'monthly' && this.subTypeMonthly == 'sequence_days' } )
			}
		},
		created(){
			var startDate = moment().startOf('year')
			this.limitsRange.min = moment().startOf('year').format('D/M/YYYY')
			this.limitsRange.max = moment().endOf('year').format('D/M/YYYY')
			this.newStartDay = new Date(startDate)
		},
		async mounted() { 
			await this.getProcedures();

            if(this.data.crud == 'edit'){
                await this.getResponsibility();
            }else{
            	this.loaded = true
            	setTimeout(() => {
            		$('.opacity-page').addClass('show')
            	}, 0)
            }

            this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                	this.openSubModal('confirm_close', this.submodal.progress_type)
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
			async getProcedures() {
				await axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, {
					params: { procedures: true, roles_ids: [this.data.roleId] }
				})
				.then(({data}) => {
					this.optionsProcedures = data.data.procedures;
				})
			},
            async getResponsibility(){
                axios.get(`/role-responsibilities/${this.data.responsabilityId}/edit`)
                .then(({data})=> {
                	this.cand_edit_measure = data.data.rights.edit_progress_type;
                	this.name = data.data.name;
                	this.description = data.data.description;
                	this.type_evaluation = data.data.type ? data.data.type : 'check';
                	this.frequency = data.data.frequency;
                	this.allDay = data.data.all_day;
                	this.measurable = this.frequency ? true : false;
					this.color_code = data.data.color_code ? data.data.color_code : '';
					this.uploadFile = data.data.upload_file ? true : false;
					this.required_run_procedure = data.data.procedure_check ? true : false;
					this.trigger = data.data.trigger ? data.data.trigger : ''

					this.selectedProcedures = data.data.procedures && data.data.procedures.length ? data.data.procedures : [];

                	if(!this.allDay && this.measurable){
                		this.startHour = data.data.start;
						this.endHour = data.data.end;
                	}

                	if(this.type_evaluation == 'value'){
						this.progressData.type = data.data.progress_type;
                        this.progressData.unity = data.data.unity;
                        this.progressData.to = data.data.target;
					}

					switch (this.frequency) {
						case 'weekly':
							this.weeklyDays = this.optionsWeeklyDays.filter(el => data.data.measurement.includes(el.name));
							break;
						case 'monthly':
							this.monthlyDays = data.data.measurement.map(function(a) {return parseInt(a) });
							break;
						case 'monthly_dynamically':
							this.subTypeMonthly = 'sequence_days'
							this.monthlyTypeSequenceDay = this.optionsMonthlyTypeSequenceDays.filter(el => data.data.measurement_dynamically.includes(el.value));
							this.weeklyDays = this.optionsWeeklyDays.filter(el => data.data.measurement.includes(el.value.toString()) || data.data.measurement.includes(el.value));
							this.frequency = 'monthly';
							break;
						case 'yearly':
							this.yearlyDays = data.data.measurement;
							this.dateOfYear = {
								selectedDate: false,
								selectedDateTime: false,
								multipleDateRange: [],
								selectedDatesItem: "",
								selectedHour: "00",
								selectedMinute: "00",
								dateRange: {end: '',start: ''},
								selectedDates: this.yearlyDays.map(function(a) { return {date: moment(a, 'MM-DD').format('D/M/YYYY'), dateTime: false, hour: "00", minute: "00"} })
							}
							break;
					}
                })
                .finally( () => {
                	this.loaded = true
                	setTimeout(() => {
                		$('.opacity-page').addClass('show')
                	}, 0)
                })
            },
            saveAction(type){
				var buttonName = `buttonCreateDepartment`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.isCorrectDate = true;
				this.$v.$touch()

				if(this.measurable && !this.allDay){
					if(typeof this.startHour === 'object') {
						var beginningTime = moment(this.startHour.HH +':'+this.startHour.mm, 'hh:mm');
					} else {
						var beginningTime = moment(this.startHour, 'hh:mm');
					}

					if(typeof this.endHour === 'object') {
						var endTime = moment(this.endHour.HH +':'+this.endHour.mm, 'hh:mm');
					} else {
						var endTime = moment(this.endHour, 'hh:mm');
					}

					if(!beginningTime.isBefore(endTime)) {
						this.isCorrectDate = false;
					}
				}

				if(!this.$v.$invalid && this.isCorrectDate){
					var objData = {
						name: this.name,
						description: this.description,
						type: this.measurable ? this.type_evaluation : '',
						frequency: this.measurable ? this.frequency : '',
						color_code: this.color_code,
						upload_file: this.uploadFile,
						procedures: this.selectedProcedures.map(el => el.id),
						procedure_check: this.required_run_procedure && this.selectedProcedures.length ? this.required_run_procedure : false
					}

					if(this.measurable) {
						if(this.type_evaluation == 'value'){
							objData.progress_type = this.progressData.type
	                        objData.unity = this.progressData.unity
	                        objData.target = this.progressData.to
						}

						switch (this.frequency) {
							case 'weekly':
								objData.measurement = this.weeklyDays.map(el => {return el.name});
								break;
							case 'monthly':
								if(this.subTypeMonthly == 'day_numbers'){
									objData.measurement = this.monthlyDays;
								} else {
									objData.measurement_dynamically = this.monthlyTypeSequenceDay.map(el => {return el.value});
									objData.measurement = this.weeklyDays.map(el => {return el.value});
									objData.frequency = 'monthly_dynamically';
								}
								break;
							case 'yearly':
								objData.measurement = this.yearlyDays;
								break;
						}

						objData.all_day = this.allDay
						if(!this.allDay){
							if(typeof this.startHour === 'object') {
								objData.start = this.startHour.HH +':'+this.startHour.mm
							} else {
								objData.start = this.startHour
							}

							if(typeof this.endHour === 'object') {
								objData.end = this.endHour.HH +':'+this.endHour.mm
							} else {
								objData.end = this.endHour
							}
						}
					} else {
						objData.trigger = this.trigger
					}

					if(type == 'create'){
						this.createResponsibility(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateResponsibility(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createResponsibility(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`/${this.data.roleId}/role-responsibilities/store`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						if(this.data.from == 'role_list') { this.$root.$emit('refreshShowRole') } else { this.$root.$emit('refreshResponsibilitiesIndex') }
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.create')
                            this.$emit("close_modal");
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
            updateResponsibility(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/role-responsibilities/${this.data.responsabilityId}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						if(this.data.from == 'role_list') { this.$root.$emit('refreshShowRole') } else { this.$root.$emit('refreshResponsibilitiesIndex') }
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
                            this.$emit("close_modal");
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
            checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                if(this.description != ''){
                    notEmpty = true
                }

                if(this.frequency != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            closeModal(){
                if(this.checkInputs()){
                	this.openSubModal('confirm_close')
                }else{
                    this.$emit("close_modal");
                }
			},
			openSubModal(type, active_tab = false) {
				if(active_tab) {
					document.getElementById(`submodal_progress_type`).classList.remove('active')
					this.submodal.progress_type = false
				}
				
				this.subModalShow = true
				this.submodal[type] = true
				setTimeout(() => {
					document.getElementById('submodalContainer').classList.add('active')
					setTimeout(() => {
						document.getElementById(`submodal_${type}`).classList.add('active')
					}, 200);
				}, 0);
			},
            closeSubModal(type){
                document.getElementById(`submodal_${type}`).classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.submodal[type] = false
                    }, 200);
                }, 200);
            },
            selectDay(day, list, fromList = false) {
            	if(this.cand_edit_measure){
	            	switch (list) {
	            		case 'weekly':
		            		if(this.weeklyDays.includes(day)) {
		            			this.weeklyDays.splice(this.weeklyDays.findIndex(el => el == day), 1)
		            		}else{
		            			this.weeklyDays.push(day)
		            		}
	            		break;
	            		case 'monthly':
		            		if(this.monthlyDays.includes(day)) {
		            			this.monthlyDays.splice(this.monthlyDays.findIndex(el => el == day), 1)
		            		} else {
		            			this.monthlyDays.push(day)
		            		}
		            		break;
	            		case "yearly":
	            			var date = day.hasOwnProperty('date') ? moment(day.date, 'D/M/YYYY').format('MM-DD') : day;

		            		if(this.yearlyDays.includes(date)) {
		            			this.yearlyDays.splice(this.yearlyDays.findIndex(el => el == date), 1)
		            			if(fromList){
		            				this.dateOfYear.selectedDates.splice(this.dateOfYear.selectedDates.findIndex(el => moment(el.date, 'D/M/YYYY').format('MM-DD') == date), 1)
		            			}
		            		} else {
		            			this.yearlyDays.push(date)
		            		}

		            		break;
					}
            	}
            },
            getProgressData(value){
				this.closeSubModal('progress_type')
				this.progressData = JSON.parse(value)
			},
			resetInputs(){
				if(this.measurable){
					this.frequency = '',
					this.uploadFile = false
				}
			}
		}
	};
</script>