<template>
	<div class="sidebar-org left">
		<div class="close-button" v-if="$resize && $mq.above(601)">
			<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
		</div>
		<div class="content-sidebar" v-if="loaded">
			<div class="header">
				<div class="title">
					<div class="text">{{ $t('departments.order_title') }}</div>
				</div>
			</div>

			<div class="actions-header">
				<template v-if="orderDepartments == false">
					<button class="btn-tbf blue center button-blue ml-auto" @click="orderDepartments = true">
						<div class="text">{{ $t('departments.order') }}</div>
					</button>
				</template>
				<template v-else>
					<button class="button-simple-header" @click="orderDepartments = false">
						<div class="text red">{{ $t('general.cancel') }}</div>
					</button>
					<button class="btn-tbf blue center button-blue ml-auto" id="saveOrderBtn" @click="saveNewOrder">
						<div class="text">{{ $t('departments.update') }}</div>
						<div class="loader-spin" v-if="savingOrder"></div>
					</button>
				</template>
			</div>


			<div class="order-departments" v-if="orderDepartments">
				<nested-draggable-departments :departments="departments" />
			</div>
			<div class="departments-list" v-else>
				<departments-order :departments="departments" />
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import NestedDraggableDepartments from "../../General/NestedDraggableDepartments";
	import DepartmentsOrder from "../../General/DepartmentsOrder";
	import IconClose from "../../Icons/Close";

	export default {
		components: {
			IconClose,
			NestedDraggableDepartments,
			DepartmentsOrder
		},
		data() {
			return {
				loaded: false,
				departments: [],
				orderDepartments: false,
				savingOrder: false
			};
		},
		mounted() {
			this.getDepartments()
		},
		methods: {
			async getDepartments(){
				await axios.get(this.$auth.user().instance.id + '/departments-structure')
				.then(({data}) => {
					this.departments = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
				})			
			},
			closeModal(){
				this.$emit("close_modal");
			},
			saveNewOrder(){
				this.savingOrder = true

				var buttonName = `saveOrderBtn`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.post(`/${this.$auth.user().instance.id}/departments/reorder-structure`, {departments: this.departments})
				.then(() => {
					btnSubmitText.innerHTML = this.$t('btn-submit.success')
					btnSubmit.classList.add('completed')
					this.$root.$emit("refreshCompanyChart")
					setTimeout(()=>{
						btnSubmit.disabled = false
						this.closeModal()
					}, 500)
				})
				.finally(() => {
					this.savingOrder = false
				})
			}
		}
	};
</script>