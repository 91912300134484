<template>
	<div class="modal-tbf">
		<div class="container-modal" v-if="loaded">
            <div class="header-modal">
				<div class="title">{{ $t('projects.advanced_settings') }}</div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>
            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50' ]">
                            <div class="label-header">
                                <label class="label">{{ $t('projects.pipeline_label') }}</label>
                            </div>
                            <div class="flex">
                                <div class="input-box bg-white w-flex">
                                    <div class="icon-left"><icon-pipeline /></div>
                                    <multiselect 
                                    v-model="pipelineSelected"
                                    :options="optionsPipelines"
                                    :allow-empty="true"
                                    :show-labels="false"
                                    open-direction="bottom"
									label="name"
									
                                    >
                                        <template slot="placeholder" slot-scope="props">
                                            <span class="text">
                                                {{ $t('projects.choose_pipeline') }}
                                            </span>
                                        </template>
                                        <template slot="noResult">{{$t('general.no_results')}}</template>
                                        <template slot="noOptions">{{$t('general.empty_list')}}</template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
					</div>
					<div class="form-submit">
                        <button class="btn-tbf blue center" @click="saveSettings" :disabled="loadingAction">
                            <div class="loader-spin" v-if="loadingAction"></div>
                            <div class="text" v-else>{{ $t('general.update') }}</div>
                        </button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconPipeline from '../Icons/IconPipeline'
	import StarRating from 'vue-star-rating'

	export default {
		data() {
			return {
				loaded: false,
				loadingAction: false,
				pipelineSelected: '',
				optionsPipelines: []
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconClose,
			IconPipeline,
        	StarRating
        },
		mounted() {
			this.getOptionsPipelines();
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			getOptionsPipelines() {
				axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {
					pipelines: true,
					pipeline_type: 'project',
					pipeline_project_slug: this.data.slug
				}})
				.then(({data}) => {
					this.optionsPipelines = data.data.pipelines;

					if(data.data.pipeline_id) {
						this.pipelineSelected = this.optionsPipelines.find(el => el.id == data.data.pipeline_id);
					}
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true 
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveSettings() {
				this.loadingAction = true;
				//Update settings project
				axios.patch(`/projects/${this.data.slug}`, { pipeline_id: this.pipelineSelected ? this.pipelineSelected.id : null })
				.then(({data}) => {
					this.$root.$emit('refreshProjectPage');
				})
				.finally(() => {
					setTimeout(() => {
						this.loadingAction = false;
						this.closeModal();
					}, 1000);
				})
			}
		}
	};
</script>