<template>
	<div class="sidebar-notifications">

		<div class="top">
			<h1>{{ $t('notifications.title') }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>

		<div class="notification-header">
			<div class="notifications-type" v-bind:class="{active: active_tab == 'activity'}" @click="changeTab('activity')">{{ $t('notifications.activity') }}</div>
			<div class="notifications-type" v-bind:class="{active: active_tab == 'new'}" @click="changeTab('new')">{{ $t('notifications.new') }} ({{ countUnread }})</div>
		</div>
			
		<div class="notifications-content" v-bind:class="{center: (active_tab == 'activity' && Object.keys(activity_notifications).length == 0) || (active_tab == 'new' && Object.keys(notifications).length == 0)}" v-if="loaded">
			<!-- Activitatea companiei -->
			<template v-if="active_tab == 'activity'">
				<div class="notification-list">
					<div class="notification-box" :id="'notification-box-'+notification.id" v-for="notification in activity_notifications" v-bind:class="{'is-link': notification.need_link}" :key="`notification-activity-${notification.id}`">
						<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right"/></div>
						<div class="center" @click="notification.need_link ?  viewItem(notification.entity_slug, notification, 'activity') : ''">
							<div class="avatar" v-if="notification.avatar != null" >
								<img :src="notification.avatar" class="img-responsabile">
								<!-- <span class="date">{{notification.created_at | moment("D MMM YYYY")}}</span> -->
							</div>
							<div class="bell" v-else><bell /></div>
							<div class="content-notification">
								<p v-html="notification.name"></p>
							</div>
						</div>
						<div class="right-side" v-if="$resize && $mq.above(600)">
						</div>
					</div>
					<infinite-loading :identifier="1" @infinite="infiniteNotificationsActivity"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
				</div>
				<div class="no-notifications" v-if="Object.keys(activity_notifications).length == 0">
					<icon-large-bell class="icon"/>
					<p>{{ $t('notifications.all-good') }}</p>
					<span>{{ $t('notifications.no-notifications') }}</span>
				</div>
			</template>

			<!-- Notificari click-uibile -->
			<template v-if="active_tab == 'new'">
				<div class="notification-list">
					<div class="notification-box" :id="'notification-box-'+notification.id" v-for="notification in notifications" v-bind:class="{'is-link': notification.need_link, 'highlighted': notification.highlighted && !notification.seen, comment: ['announce_comment', 'announce_comment_highlighted'].includes(notification.type)}" :key="`notification-${notification.id}`">
						<div class="left-side" v-if="$resize && $mq.above(600)"></div>
						<div class="center" >
							<div class="avatar" v-if="notification.avatar != null" >
								<img :src="notification.avatar" class="img-responsabile">
								<!-- <span class="date">{{notification.created_at | moment("D MMM YYYY")}}</span> -->
							</div>
							<div class="bell" v-else><bell /></div>
							<div class="content-notification" v-bind:class="{new: !notification.seen}">
								<!-- <span class="date">{{notification.created_at | moment("D MMMM YYYY")}}</span> -->
								<div class="clickable" @click="notification.need_link ?  viewItem(notification.entity_slug, notification, 'personal') : ''">
									<p v-html="notification.name"></p>
								</div>

								<div class="answer-comment" v-if="['announce_comment', 'announce_comment_highlighted'].includes(notification.type) && !notification.is_deleted">
									<div class="completed-msg" v-if="completedStoreComment == notification.id">{{ $t('notifications.answer_completed') }}</div>
									<!-- <div class="answer-now" @click="answerActive = notification.id" v-else-if="answerActive != notification.id">{{ $t('notifications.answer_comment') }}</div> -->
									<div class="input-group" v-else>
										<input type="text" v-model="answerComment[notification.id]" v-on:keyup.enter="storeComment(notification.entity_slug, notification.id)" :placeholder="$t('notifications.answer_comment')"/>
										<button class="send-comment" @click="storeComment(notification.entity_slug, notification.id)">
											<div class="loader-spin" v-if="loadingSendComment == notification.id"></div>
											<icon-send v-else/>
										</button>
									</div>
								</div>

							</div>
						</div>
						<div class="right-side" v-if="$resize && $mq.above(600)">
						</div>
					</div>
					<infinite-loading :identifier="2" @infinite="infiniteNotifications"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
				</div>
				<div class="no-notifications" v-if="Object.keys(notifications).length == 0">
					<icon-large-bell class="icon"/>
					<p>{{ $t('notifications.all-good') }}</p>
					<span>{{ $t('notifications.no-notifications') }}</span>
				</div>
			</template>

		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import Bell from "../Icons/Bell"
	import IconSend from '../Icons/Send'
	import IconArrow from '../Icons/Arrow'
	import IconLargeBell from '../Icons/LargeBell'
	import InfiniteLoading from 'vue-infinite-loading'

	export default {
		data() {
			return {
				loaded: true,
				notifications: [],
				activity_notifications: [],
				countUnread: 0,
				// read_notifications: {},
				active_tab: 'new',
				view_more: 20,
				counter: 1,
				viewMoreUnread: 20,
				counterUnread: 1,
				answerComment: [],
				answerActive: 0,
				completedStoreComment: 0,
				loadingSendComment: false,
				procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
				objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK
			};
		},
		components: {
			InfiniteLoading,
			IconClose,
			Bell,
			IconArrow,
			IconLargeBell,
			IconSend
		},
		async mounted() {
			await this.getNotifications();

			this.$root.$emit('clear_notifications');

			this.$root.$on("updateNotificationsList", (parsedData) => {
				if(parsedData.for_activity){
					this.activity_notifications.unshift(parsedData);
				}
				if(parsedData.for_personal){
					this.notifications.unshift(parsedData);
				}
			});
		},
		methods: {
			async getNotifications(){
				await axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`).then(({data}) => {
					this.notifications = data.data.notification_logs
					this.countUnread = data.data.count_unread
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.active_tab = 'new' })
			},
			getActivityNotifications(){
				axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list?type=activity`).then(({data}) => {
					this.activity_notifications = data.data.notification_logs
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { 
					this.active_tab = 'activity'
					this.loaded = true 
				})
			},
			infiniteNotifications($state2){
				axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`, {params: {view_more: this.viewMoreUnread }}).then(({data}) => {
					if(data.data.notification_logs.length){
						this.counterUnread++
						this.viewMoreUnread = 20 * this.counterUnread
						this.notifications.push(...data.data.notification_logs)
						$state2.loaded()
					}else{
						$state2.complete()
					}
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			infiniteNotificationsActivity($state){
				axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`, {params: {type: 'activity', view_more: this.view_more }})
				.then(({data}) => {
					if(data.data.notification_logs.length){
						this.counter++
						this.view_more = 20 * this.counter
						this.activity_notifications.push(...data.data.notification_logs)
						$state.loaded()
					}else{
						$state.complete()
					}
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			viewItem(itemSlug, notification, column){
				if (notification.frontend_type == 'evaluations') {
					this.$router.push({ name: 'user-show', params: { slug: itemSlug }, query: { tab: 'assessments' }})
				} else if (notification.frontend_type == 'announces') {
					this.$router.push({ name: 'announcements-show', params: { id: itemSlug } })
				} else if (notification.frontend_type == 'tasks') {
					this.$router.push({ name: 'dashboard' })
				} else if (notification.frontend_type == 'departments') {
					this.$router.push({ name: 'company' })
				} else if (notification.frontend_type == 'procedures') {
					window.location.href = `${this.procedures_fe_link}/procedure/${itemSlug}`
				}else if (notification.frontend_type == 'processes') {
					window.location.href = `${this.procedures_fe_link}/process/${itemSlug}`
				}else {
                    window.location.href = `${this.objectives_fe_link}/obiectiv/${itemSlug}`
                }

				if(column == "personal"){ this.markAsRead(notification); }
				this.closeModal();
			},
			markAsRead(notification){
				axios.patch(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notifications/${notification.id}`)
				.then(({data}) => {
					var contentNotification = document.querySelector(`#notification-box-${notification.id} .content-notification`)
					if(contentNotification){contentNotification.classList.remove('new')}
				});
			},
			closeModal(){
				this.$emit("close_modal");
			},
			changeTab(val) {
				if(val == 'new'){
					this.getNotifications();
				} else {
					this.getActivityNotifications();
				}
			},
			storeComment(announcementId, notificationId) {
				this.loadingSendComment = notificationId;

				if(this.answerComment[notificationId]){
					axios.post('/comments/store', { 
						announce_id: announcementId,
						body: this.answerComment[notificationId],
					 })
					.then(() => {
						this.completedStoreComment = this.answerActive;
						this.loadingSendComment = false;
						this.answerActive = 0;
						this.answerComment = '';
					})
					.catch(error => {
						this.loadingSendComment = false;
					})
					.finally(() => {
						setTimeout(() => {
							this.completedStoreComment = 0;
						}, 2000)
					})
				}else {
					this.loadingSendComment = false;
				}
			}
		}
	};
</script>