<template>
	<div class="modal-tbf modal-crud-responsibility crud-task with-comments">
		<template v-if="loaded">
			<div class="container-modal-flex opacity-page" v-if="!taskError">
				<HeaderTask
					:data="{
						...data,
						name: taskData.name,
						frequency: taskData.frequency,
						deadlineDate: taskData.deadlineDate,
						dateOfStart: taskData.dateOfStart,
						responsible: taskData.responsible,
						project: taskData.project,
						selectedTags: taskData.selectedTags,
						duration: taskData.duration,
						estimation: taskData.estimation,
						stageId: stage_id,
						pipelineId: pipeline_id,
						oldDate: oldTaskData.date,
						done: isDone,
						levelOfPriority: taskData.levelOfPriority,
						watchers: taskData.watchers,
					}"
					:optionsProjects="optionsProjects"
					:optionsTags="optionsTags"
					@openModal="openSubModalFromHeader"
					@updateData="updateDataFromHeader"
					@changeView="toggleViewHistory"
				/>

				<div class="content-group">
					<TaskHistory :taskType="data.type" :taskId="data.taskEditId" :dateTask="dateTask" v-if="historyContent" />
					<template v-else>
						<div class="info-content">
							<div class="selectedTags" v-if="taskData.selectedTags.length">
								<div
									class="tag"
									:class="{ is_light: tag.light }"
									v-for="(tag, index) in taskData.selectedTags"
									:style="`--color: ${tag.color_code}; --background-color: ${tag.color_code}30`"
								>
									<div class="text" :style="`--color: ${tag.color_code}`">{{ tag.name }}</div>
									<div class="icon" @click="deleteTag(index)">
										<icon-close :style="`--color: ${tag.color_code}`" />
									</div>
								</div>
							</div>

							<div class="form-section">
								<!-- Field Name -->
								<div class="input-group w-special-110">
									<div class="label-header">
										<label class="label">{{ $t("general.name") }}</label>
										<div v-if="$v.taskData.name.$error" class="error-msg">{{ !$v.taskData.name.required ? $t("validator.required") : $t("validator.maxlength_255") }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{ has_error: $v.taskData.name.$error }">
										<div class="icon-left"><icon-circle-check /></div>
										<input type="text" :placeholder="$t('general.name_placeholder')" class="input-text no-icon" v-model="taskData.name" />
										<div class="icon-right" v-if="taskData.name != ''" @click.stop="taskData.name = ''"><icon-close class="icon-clear" /></div>
									</div>
								</div>
								<!-- Field Responsible -->
								<div class="input-group width-fix-110">
									<div class="label-header">
										<label class="label">{{ $t("tree-chart.responsible") }}</label>
									</div>
									<div class="filter-dropdown-header avatars-dropdown" id="boxDropdownAvatars">
										<div
											class="user-avatar"
											id="dropdownAvatars"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											v-bind:class="{ has_error: $v.taskData.responsible.$error }"
										>
											<v-popover
												offset="5"
												trigger="hover"
												placement="bottom"
												popoverBaseClass="popover-tbf"
												:delay="{ show: 200, hide: 0 }"
												:disabled="!taskData.responsible"
												v-if="taskData.responsible"
											>
												<img :src="taskData.responsible.avatar" />

												<template slot="popover">
													<div class="simple-text" v-if="taskData.responsible">{{ taskData.responsible.name }}</div>
												</template>
											</v-popover>
											<div class="icon" v-else>
												<icon-user />
											</div>
										</div>
										<div class="dropdown-menu" aria-labelledby="dropdownAvatars">
											<div class="search-filter-dropdown">
												<input type="text" v-model="searchUsers" :placeholder="$t('add_task.choose_responsible')" />
											</div>

											<template v-for="user in filteredUsers">
												<div class="dropdown-item" :class="{ selected: taskData.responsible && taskData.responsible.id == user.id }" @click="handleResponsible(user)">
													<img class="avatar" :src="user.avatar" />
													<span class="name">{{ user.name }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
								<!-- Field Description -->
								<div class="input-group w-100">
									<div class="label-header">
										<label class="label">{{ $t("responsibility.description") }}</label>
									</div>
									<div class="input-box bg-white" v-bind:class="{ expanded: expandQuill == 'true', shrinked: expandQuill == 'false' }">
										<quill-editor class="editor-tbf h-300" ref="myQuillEditor" v-model="taskData.description" :options="editorOption" />
										<div class="expand" @click="expandQuill == 'true' ? (expandQuill = 'false') : (expandQuill = 'true')">
											<icon-expand class="icon" v-bind:class="{ rotated: expandQuill == 'true', toInitial: expandQuill == 'false' }" />
										</div>
									</div>
								</div>

								<!-- Field Frequency -->
								<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
									<div class="label-header">
										<label class="label">{{ $t("responsibility.frequency") }}</label>
										<div v-if="$v.taskData.frequency.$error" class="error-msg">{{ $t("validator.required") }}</div>
									</div>
									<div class="flex">
										<div class="input-box bg-white w-flex" v-bind:class="{ has_error: $v.taskData.frequency.$error }">
											<div class="icon-left"><icon-repeat /></div>
											<multiselect
												v-model="taskData.frequency"
												:options="optionsFrequency"
												:allow-empty="false"
												:show-labels="false"
												open-direction="top"
												@select="checkFrequency"
											>
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{ $t("responsibility.choose-frequency") }}
													</span>
												</template>
												<template slot="singleLabel" slot-scope="props">{{ $t(`responsibility.frequencies.${props.option}`) }}</template>
												<template slot="option" slot-scope="props">{{ $t(`responsibility.frequencies.${props.option}`) }}</template>
												<template slot="noResult">{{ $t("general.no_results") }}</template>
												<template slot="noOptions">{{ $t("general.empty_list") }}</template>
											</multiselect>
										</div>
										<div class="clock" :class="{ active: hasEndHour == true }">
											<div class="icon-clock" @click.stop="openTimePicker"><icon-clock class="icon" /></div>
											<input v-model="taskData.time" id="timeInput" type="text" class="time-picker" :placeholder="$t('add_task.choose_hour')" />
											<div class="icon-right" v-if="taskData.time != ''" @click.stop="clearDeadlineHour"><icon-close class="icon-clear" /></div>
										</div>
									</div>
								</div>

								<template v-if="taskData.frequency == 'one_time'">
									<!-- Field Deadline -->
									<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
										<div class="label-header">
											<label class="label">{{ $t("add_task.deadline") }}</label>
										</div>
										<div class="flex">
											<div class="input-box bg-white w-flex">
												<div class="icon-left"><icon-calendar /></div>
												<div class="icon-right" v-if="Object.keys(taskData.deadlineDate).length" @click.stop="taskData.deadlineDate = {}">
													<icon-close class="icon-clear" />
												</div>
												<FunctionalCalendar ref="CalendarDateOfEmployment" v-model="taskData.deadlineDate" class="calendar-tbf deadline" :configs="calendarConfigs">
													<template v-slot:datePickerInput="props">
														<input
															class="vfc-single-input custom-input-picker"
															type="text"
															:value="moment(props.selectedDate, 'D/M/YYYY').format('DD MMM YYYY')"
															v-if="Object.keys(taskData.deadlineDate).length"
														/>

														<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t("add_task.choose_deadline") }}</div>
													</template>
												</FunctionalCalendar>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<!-- Field Yearly -->
									<div class="input-group w-100" v-if="taskData.frequency == 'yearly'">
										<div class="label-header">
											<label class="label">{{ $t("add_task.select_date") }}</label>
											<div v-if="$v.taskData.yearlyDays.$error" class="error-msg">{{ $t("validator.required") }}</div>
										</div>
										<div class="input-box bg-white-all-time">
											<FunctionalCalendar
												ref="CalendarDateOfEmployment"
												v-model="taskData.dateOfYear"
												class="calendar-tbf frequency"
												:is-multiple-date-picker="true"
												:is-multiple="true"
												:calendars-count="3"
												:is-separately="false"
												:change-month-step="3"
												:hidden-elements="['leftAndRightDays']"
												:limits="limitsRange"
												:newCurrentDate="newStartDay"
												@dayClicked="selectDay($event, 'yearly')"
											>
												<template v-slot:datePickerInput="props">
													<input
														class="vfc-single-input custom-input-picker"
														type="text"
														:value="props.selectedDate ? moment(props.selectedDate, 'YYYY-M-D').format('DD MMMM') : ''"
														v-if="Object.keys(taskData.dateOfYear).length"
													/>

													<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t("add_task.select_date") }}</div>
												</template>
											</FunctionalCalendar>
										</div>
									</div>

									<template v-if="taskData.frequency != ''">
										<div class="input-group w-50 measurement" v-if="taskData.frequency == 'monthly'">
											<div class="label-header">
												<label class="label">{{ $t("responsibility.type_monthly_label") }}</label>
												<div v-if="$v.taskData.monthlyTypeSequenceDay.$error" class="error-msg">{{ $t("validator.required") }}</div>
											</div>
											<div class="input-box-with-dropdown">
												<div class="input-box box-select small-padd">
													<multiselect
														v-model="taskData.subTypeMonthly"
														:options="optionsMonthlyTypes"
														:allow-empty="false"
														:show-labels="false"
														:placeholder="$t('general.choose')"
													>
														<template slot="singleLabel" slot-scope="props">{{ $t(`responsibility.type_monthly.${props.option}`) }}</template>
														<template slot="option" slot-scope="props">{{ $t(`responsibility.type_monthly.${props.option}`) }}</template>
														<template slot="noResult">{{ $t("general.no_results") }}</template>
														<template slot="noOptions">{{ $t("general.empty_list") }}</template>
													</multiselect>
												</div>

												<div class="input-box value-type" v-if="taskData.subTypeMonthly == 'day_numbers'">
													<div class="icon-left"><icon-circle-check /></div>
													<div class="input-text-simple">{{ $t("responsibility.type_check_day_numbers") }}</div>
												</div>
												<div
													class="input-box value-type"
													v-bind:class="{ has_error: $v.taskData.monthlyTypeSequenceDay.$error }"
													v-else-if="taskData.subTypeMonthly == 'sequence_days'"
												>
													<div class="icon-left"><icon-repeat /></div>
													<multiselect
														class="hide-tags multiselect_checkbox"
														v-model="taskData.monthlyTypeSequenceDay"
														v-bind:class="{ populate: taskData.monthlyTypeSequenceDay }"
														:options="optionsMonthlyTypeSequenceDays"
														:allow-empty="true"
														:show-labels="false"
														:close-on-select="false"
														:multiple="true"
														track-by="value"
														label="name"
													>
														<template slot="placeholder" slot-scope="props">
															<span class="text">
																{{ $t("responsibility.choose_monthly_sequence_days") }}
															</span>
														</template>
														<template slot="selection" slot-scope="{ values, search, isOpen }">
															<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
																$t(`responsibility.monthly_sequence_days_option.${values[0].name}`)
															}}</span>
															<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t("filters.selected") }}</span>
														</template>
														<template slot="option" slot-scope="props">
															<div class="checkbox">
																<div class="checkmark"></div>
																<span class="text">{{ $t(`responsibility.monthly_sequence_days_option.${props.option.name}`) }}</span>
															</div>
														</template>
														<template slot="noResult">{{ $t("general.no_results") }}</template>
														<template slot="noOptions">{{ $t("general.empty_list") }}</template>
													</multiselect>
												</div>
											</div>
										</div>
										<div
											class="input-group w-50 measurement"
											v-if="taskData.frequency == 'weekly' || (taskData.frequency == 'monthly' && taskData.subTypeMonthly == 'sequence_days')"
										>
											<div class="label-header">
												<label class="label">{{ $t("add_task.select_date") }}</label>
												<div v-if="$v.taskData.weeklyDays.$error" class="error-msg">{{ $t("validator.required") }}</div>
											</div>
											<div class="input-select-days" v-bind:class="{ has_error: $v.taskData.weeklyDays.$error }">
												<div class="select-days weekly">
													<div class="checkbox-days" v-for="day in optionsWeeklyDays">
														<span @click="selectDay(day, 'weekly')" class="checkmark" v-bind:class="{ active: taskData.weeklyDays.includes(day) }">{{
															$t(`calendar.small_days.${day.name}`)
														}}</span>
													</div>
												</div>
											</div>
										</div>
										<div class="input-group w-100 measurement" v-else-if="taskData.frequency == 'monthly'">
											<div class="label-header">
												<label class="label">{{ $t("add_task.select_date") }}</label>
												<div v-if="$v.taskData.monthlyDays.$error" class="error-msg">{{ $t("validator.required") }}</div>
											</div>
											<div class="input-select-days" v-bind:class="{ has_error: $v.taskData.monthlyDays.$error }">
												<div class="select-days">
													<div class="checkbox-days" v-for="day in optionsMonthlyDays">
														<span @click="selectDay(day, 'monthly')" class="checkmark" v-bind:class="{ active: taskData.monthlyDays.includes(day) }">{{ day }}</span>
													</div>
												</div>
											</div>
										</div>
									</template>

									<div class="clear-100"></div>
									<!-- Field date of start -->
									<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
										<div class="label-header">
											<label class="label">{{ $t("add_task.start_date") }}</label>
											<div v-if="$v.taskData.dateOfStart.$error" class="error-msg">{{ $t("validator.required") }}</div>
										</div>
										<div class="input-box bg-white w-flex" v-bind:class="{ has_error: $v.taskData.dateOfStart.$error }">
											<div class="icon-left"><icon-calendar /></div>
											<div class="icon-right" v-if="Object.keys(taskData.dateOfStart).length" @click.stop="taskData.dateOfStart = {}"><icon-close class="icon-clear" /></div>
											<FunctionalCalendar
												ref="CalendarDateOfEmployment"
												v-model="taskData.dateOfStart"
												v-on:choseDay="newStartDate"
												class="calendar-tbf deadline"
												:configs="calendarConfigs"
											>
												<template v-slot:datePickerInput="props">
													<input
														class="vfc-single-input custom-input-picker"
														type="text"
														:value="moment(props.selectedDate, 'D/M/YYYY').format('DD MMM YYYY')"
														v-if="Object.keys(taskData.dateOfStart).length"
													/>

													<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t("add_task.choose_start_date") }}</div>
												</template>
											</FunctionalCalendar>
										</div>
									</div>
									<!-- Field date of ending -->
									<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
										<div class="label-header">
											<label class="label">{{ $t("add_task.end_date") }}</label>
										</div>
										<div class="flex">
											<div class="input-box bg-white w-flex">
												<div class="icon-left"><icon-calendar /></div>
												<div class="icon-right" v-if="Object.keys(taskData.deadlineDate).length" @click.stop="taskData.deadlineDate = {}">
													<icon-close class="icon-clear" />
												</div>
												<FunctionalCalendar
													ref="CalendarDateOfEmployment"
													v-model="taskData.deadlineDate"
													class="calendar-tbf deadline"
													:configs="configsDateOfFinish"
													:key="keyCalendarEndDate"
												>
													<template v-slot:datePickerInput="props">
														<input
															class="vfc-single-input custom-input-picker"
															type="text"
															:value="moment(props.selectedDate, 'D/M/YYYY').format('DD MMM YYYY')"
															v-if="Object.keys(taskData.deadlineDate).length"
														/>

														<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t("add_task.choose_end_date") }}</div>
													</template>
												</FunctionalCalendar>
											</div>
										</div>
									</div>
								</template>

								<div class="input-group w-100">
									<div class="label-header">
										<label class="label">{{ $t("show_user.files") }}</label>
									</div>

									<div class="attachments">
										<div class="files">
											<div class="file" v-for="file in taskData.files">
												<div class="icon"><icon-file /></div>
												<div class="name" @click="downloadFile(file)">
													{{ file.filename }}
												</div>
												<div class="icon delete" @click="deleteFile(file.id)"><icon-delete /></div>
											</div>
										</div>
										<vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
									</div>
								</div>
							</div>

							<div class="form-submit">
								<button id="buttonPrimarySubmit" class="btn-tbf blue center" @click="saveTask(data.crud == 'edit' ? 'update' : 'create')" :disabled="loadingSaveTask">
									<div class="loader-spin" v-if="loadingSaveTask"></div>
									<div class="text" v-else>{{ data.crud == "edit" ? $t("general.update") : $t("general.add") }}</div>
								</button>
							</div>
						</div>
						<sidebar-comments :comments="comments" v-if="data.crud == 'edit'" />
					</template>
				</div>
			</div>
			<div class="error-content" v-else>
				<div class="image"><img src="/build/images/warning.svg" /></div>
				<div class="description">
					{{ $t("add_meeting.meeting_error") }}
				</div>
				<div class="form-submit">
					<button id="buttonPrimarySubmit" class="btn-tbf blue center" @click="refreshAll()">
						<div class="text">{{ $t("general.update") }}</div>
					</button>
				</div>
			</div>
		</template>
		<task-modal-loader v-else />

		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-delete
				id="submodal_delete"
				v-if="submodal.delete"
				:data="{
					type: 'task',
					model: {
						id: parentTaskId,
						is_recurrence: oldTaskData.frequency ? true : false,
						date: data.date,
						from_meeting_id: data.hasOwnProperty('from_meeting_id') ? data.from_meeting_id : null,
						from_meeting_step: data.hasOwnProperty('from_meeting_step') ? data.from_meeting_step : null,
					},
					from: 'index_tasks',
				}"
				@close_modal="$emit('close_modal')"
				@cancel_close="closeSubModal('delete')"
			/>

			<modal-confirm-close id="submodal_confirm_close" v-if="submodal.confirm_close" @close_modal="$emit('close_modal')" @cancel_close="closeSubModal('confirm_close')" />
			<modal-edit-tag id="submodal_edit_tag" v-if="submodal.edit_tag" :data="objDataForSubModal" @refresh_filters="getFilters()" @cancel_close="closeSubModal('edit_tag')" />
			<modal-choose-save-mode
				id="submodal_choose_save_mode"
				v-if="submodal.choose_save_mode"
				:options="optionsSaveMode"
				:data="dataChooseSaveMode"
				@cancel_close="closeSubModal('choose_save_mode')"
			/>
			<modal-advanced-settings
				id="submodal_advanced_settings"
				v-if="submodal.advanced_settings"
				:data="{
					id: parentTaskId,
					name: taskData.name,
					pipeline_id: pipeline_id,
					responsible_id: taskData.responsible.id,
					project_id: taskData.project.id,
					is_private: is_private,
					crud: data.crud,
				}"
				@cancel_close="closeSubModal('advanced_settings')"
			/>
		</div>
	</div>
</template>

<script>
import TaskModalLoader from "../PagesLoaders/TaskModalLoader.vue";
import HeaderTask from "./TaskSections/Header.vue";
import SidebarComments from "./SideModalComments";
import TaskHistory from "./TaskSections/History.vue";

import ModalDelete from "../Modals/Delete";
import ModalConfirmClose from "../Modals/ConfirmClose";
import ModalEditTag from "../Modals/EditTag";
import ModalChooseSaveMode from "../Modals/ChooseSaveMode";
import ModalAdvancedSettings from "./TaskSections/TaskAdvancedSettings";

//ICONS
import IconClose from "@/components/Icons/Close";
import IconCircleCheck from "@/components/Icons/CircleCheck";
import IconExpand from "@/components/Icons/Expand";
import IconRepeat from "@/components/Icons/Repeat";
import IconClock from "@/components/Icons/Clock";
import IconCalendar from "@/components/Icons/Calendar";
import IconUser from "@/components/Icons/User";
import IconFile from "@/components/Icons/File";
import IconDelete from "@/components/Icons/Close";

import { required, requiredIf, maxLength, helpers } from "vuelidate/lib/validators";
import Quill from "quill";

import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js";
Quill.register("modules/imageUploader", ImageUploader);

import QuillImageDropAndPaste from "quill-image-drop-and-paste";
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import timepicker from "timepicker";

export default {
	components: {
		TaskModalLoader,
		HeaderTask,
		ModalDelete,
		ModalConfirmClose,
		ModalEditTag,
		ModalChooseSaveMode,
		ModalAdvancedSettings,
		SidebarComments,
		TaskHistory,
		vueDropzone: vue2Dropzone,
		//ICONS
		IconClose,
		IconCircleCheck,
		IconExpand,
		IconRepeat,
		IconClock,
		IconCalendar,
		IconUser,
		IconFile,
		IconDelete,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			loaded: false,
			loadingSaveTask: false,
			taskError: false,
			oldTaskData: {},
			optionsProjects: [],
			optionsTags: [],
			optionsUsers: [],
			stages: [],
			taskData: {
				name: "",
				description: "",
				responsible: "",
				deadlineDate: {},
				time: "",
				levelOfPriority: "",
				frequency: "one_time",
				selectedTags: [],
				dateOfStart: {},
				weeklyDays: [],
				monthlyDays: [],
				monthlyTypeSequenceDay: [],
				yearlyDays: [],
				subTypeMonthly: "day_numbers",
				dateOfYear: {},
				estimation: "",
				duration: "",
				project: "",
				files: [],
				watchers: [],
			},
			isDone: 0,
			parentTaskId: "",
			dateTask: "",
			uniqueId: "",
			pipeline_id: "",
			stage_id: "",
			is_private: 0,
			configsDateOfFinish: {
				isDatePicker: true,
				isDateRange: false,
				isModal: true,
				isAutoCloseable: true,
				changeMonthFunction: true,
				changeYearFunction: true,
				limits: {
					min: "",
					max: "",
				},
			},
			searchUsers: "",
			editorOption: {
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "brand");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
				},
			},
			expandQuill: "",
			hasEndHour: false,
			calendarConfigs: {
				isDatePicker: true,
				isDateRange: false,
				isModal: true,
				isAutoCloseable: true,
				changeMonthFunction: true,
				changeYearFunction: true,
				disabledDates: ["beforeToday"],
			},
			limitsRange: { min: "", max: "" },
			newStartDay: "",
			keyCalendarEndDate: 1,

			//Frequency data
			optionsFrequency: ["one_time", "daily", "weekly", "monthly", "yearly"],
			optionsMonthlyTypes: ["day_numbers", "sequence_days"],
			optionsMonthlyTypeSequenceDays: [
				{ name: "first", value: 0 },
				{ name: "second", value: 1 },
				{ name: "third", value: 2 },
				{ name: "fourth", value: 3 },
				{ name: "last", value: 4 },
			],
			optionsWeeklyDays: [
				{ name: "monday", value: 1 },
				{ name: "tuesday", value: 2 },
				{ name: "wednesday", value: 3 },
				{ name: "thursday", value: 4 },
				{ name: "friday", value: 5 },
				{ name: "saturday", value: 6 },
				{ name: "sunday", value: 7 },
			],
			optionsMonthlyDays: Array.from({ length: 31 }, (_, i) => i + 1),

			// View History
			historyContent: false,
			// Sub Modal Data
			subModalShow: false,
			objDataForSubModal: {},
			submodal: {
				confirm_close: false,
				delete: false,
				edit_tag: false,
				choose_save_mode: false,
				advanced_settings: false,
			},
			activeSubmodal: "",
			comments: [],
			dropzoneTaskId: "",
			dropzoneOptions: {
				url: `${process.env.VUE_APP_API_URL}/tasks/${this.parentTaskId}/manage-files`,
				thumbnailWidth: 150,
				maxFilesize: 10,
				addRemoveLinks: true,
				autoProcessQueue: false,
				headers: { Authorization: `Bearer ${this.$auth.token()}` },
				dictDefaultMessage: `${this.$t("add_task.files_placeholder")}`,
			},
			filesToRemove: [],
		};
	},
	created() {
		var startDate = moment().startOf("year");
		this.limitsRange.min = moment()
			.startOf("year")
			.format("D/M/YYYY");
		this.limitsRange.max = moment()
			.endOf("year")
			.format("D/M/YYYY");
		this.newStartDay = new Date(startDate);
	},
	computed: {
		filteredUsers() {
			return getByKeywordFilter(this.optionsUsers, this.searchUsers);
		},
	},
	async mounted() {
		if (this.data.crud == "edit") {
			await this.getFilters();
			await this.getTask();
		} else {
			await this.getFilters().then(() => {
				this.loaded = true;
				setTimeout(() => {
					$(".opacity-page").addClass("show");
				}, 0);
			});

			if (this.data.end) {
				this.taskData.deadlineDate = {
					currentDate: moment().format("D/M/YYYY"),
					selectedDate: moment(this.data.end).format("D/M/YYYY"),
					multipleDateRange: [],
					selectedDates: [],
					selectedDateTime: false,
					selectedDatesItem: "",
					selectedHour: "00",
					selectedMinute: "00",
					dateRange: { end: "", start: "" },
				};
				if (!this.data.all_day) {
					this.taskData.time = moment(this.data.end).format("HH:mm");
					this.hasEndHour = true;
				}

				var dataEnd = moment(this.data.end).format("D/M/YYYY");
				this.newStartDate({ date: dataEnd });
			}
		}

		var refreshWaitingLoaded = setInterval(() => {
			if (this.loaded) {
				this.toggleTimeInputs();
				clearInterval(refreshWaitingLoaded);
			}
		}, 50);

		this.$root.$on("confirm_close", () => {
			this.closeModal();
		});
	},
	validations: {
		taskData: {
			name: { required, maxLength: maxLength(255) },
			weeklyDays: {
				required: requiredIf(function() {
					return this.taskData.frequency == "weekly" || (this.taskData.frequency == "monthly" && this.taskData.subTypeMonthly == "sequence_days");
				}),
			},
			monthlyDays: {
				required: requiredIf(function() {
					return this.taskData.frequency == "monthly" && this.taskData.subTypeMonthly == "day_numbers";
				}),
			},
			yearlyDays: {
				required: requiredIf(function() {
					return this.taskData.frequency == "yearly";
				}),
			},
			monthlyTypeSequenceDay: {
				required: requiredIf(function() {
					return this.taskData.frequency == "monthly" && this.taskData.subTypeMonthly == "sequence_days";
				}),
			},
			frequency: { required },
			dateOfStart: {
				required: requiredIf(function() {
					return this.taskData.frequency != "one_time";
				}),
			},
			responsible: {
				required: requiredIf(function() {
					return !this.taskData.project;
				}),
			},
		},
	},
	methods: {
		async getTask() {
			// If has task_id, edit on parent else edit on log by task_log_id
			var paramsQuery = {};
			paramsQuery.date = this.data.date ? this.data.date : null;

			await axios
				.get(`/tasks/${this.data.taskEditId}/edit`, { params: paramsQuery })
				.then(({ data }) => {
					//Make a copy from taskData
					this.oldTaskData = Object.freeze(JSON.parse(JSON.stringify(data.data)));

					this.parentTaskId = data.data.parent_id;
					this.dateTask = data.data.date;
					this.uniqueId = data.data.unique_id;
					this.pipeline_id = data.data.pipeline ? data.data.pipeline.id : "";
					this.is_private = data.data.is_private;
					this.stage_id = data.data.stage ? data.data.stage.id : "";
					this.comments = data.data.comments ? data.data.comments : [];
					this.isDone = data.data.done ? data.data.done : 0;

					// this.viewButtonComplete = this.data.from == 'project' ? false : true;
					Object.assign(this.taskData, {
						name: data.data.name,
						description: data.data.description,
						responsible: data.data.user,
						levelOfPriority: data.data.priority,
						frequency: data.data.frequency ? data.data.frequency : "one_time",
						selectedTags: data.data.tags.map((el) => {
							return { ...el, light: this.lightOrDark(el.color_code) };
						}),
						deadlineDate: {},
						// deadline: { date: {}, endHour: '' },
						project: data.data.project ? this.optionsProjects.find((el) => el.id == data.data.project.id) : "",
						estimation: data.data.estimation ? data.data.estimation : "",
						duration: data.data.duration ? data.data.duration : "",
					});

					if (data.data.files) {
						this.taskData.files = data.data.files;
					}

					if (data.data.task_watchers) {
						this.taskData.watchers = data.data.task_watchers;
					}

					if (data.data.start_date) {
						this.taskData.dateOfStart = {
							selectedDate: moment(data.data.start_date, "YYYY-MM-DD").format("D/M/YYYY"),
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: { end: "", start: "" },
						};

						this.configsDateOfFinish.limits = {
							min: moment(data.data.date, "YYYY-MM-DD").format("D/M/YYYY"),
							max: "",
						};
					}

					if (data.data.deadline) {
						this.taskData.deadlineDate = {
							selectedDate: moment(data.data.deadline, "YYYY-MM-DD").format("D/M/YYYY"),
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: { end: "", start: "" },
						};
					}

					if (data.data.deadline_time) {
						this.taskData.time = data.data.deadline_time;
						this.hasEndHour = true;
					}

					if (data.data.frequency) {
						switch (this.taskData.frequency) {
							case "one_time":
								// Case it's not recurrence
								break;
							case "weekly":
								this.taskData.weeklyDays = this.optionsWeeklyDays.filter((el) => data.data.measurement.includes(el.name));
								break;
							case "monthly":
								this.taskData.monthlyDays = data.data.measurement.map(function(a) {
									return parseInt(a);
								});
								break;
							case "monthly_dynamically":
								this.taskData.subTypeMonthly = "sequence_days";
								this.taskData.monthlyTypeSequenceDay = this.optionsMonthlyTypeSequenceDays.filter((el) => data.data.measurement_dynamically.includes(el.value));
								this.taskData.weeklyDays = this.optionsWeeklyDays.filter((el) => data.data.measurement.includes(el.value));
								this.taskData.frequency = "monthly";
								break;
							case "yearly":
								this.taskData.yearlyDays = data.data.measurement;
								this.taskData.dateOfYear = {
									selectedDate: false,
									selectedDateTime: false,
									multipleDateRange: [],
									selectedDatesItem: "",
									selectedHour: "00",
									selectedMinute: "00",
									dateRange: { end: "", start: "" },
									selectedDates: this.taskData.yearlyDays.map(function(a) {
										return { date: moment(a, "MM-DD").format("D/M/YYYY"), dateTime: false, hour: "00", minute: "00" };
									}),
								};
								break;
						}
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 404) {
							this.taskError = true;
						}
					}
				})
				.finally(() => {
					this.loaded = true;
					setTimeout(() => {
						$(".opacity-page").addClass("show");
					}, 0);
				});
		},
		async getFilters() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: {
						users: true,
						tags: true,
						projects: true,
					},
				})
				.then(({ data }) => {
					this.optionsUsers = data.data.users;
					this.optionsProjects = data.data.projects ? data.data.projects : [];
					this.optionsTags = data.data.tags.map((el) => {
						return { ...el, light: this.lightOrDark(el.color_code) };
					});
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					if (this.data.crud != "edit") {
						this.taskData.responsible = {
							id: this.$auth.user().id,
							avatar: this.$auth.user().avatar,
							name: this.$auth.user().first_name,
						};

						this.loaded = true;
						setTimeout(() => {
							$(".opacity-page").addClass("show");
						}, 0);
					}
				});
		},
		saveTask(type) {
			var isRecurrence = this.taskData.frequency != "one_time";
			this.loadingSaveTask = true;
			this.$v.$touch();

			if (!this.$v.$invalid) {
				var object = {
					name: this.taskData.name,
					user_id: this.taskData.responsible ? this.taskData.responsible.id : "",
					priority: this.taskData.levelOfPriority,
					description: this.taskData.description ? this.taskData.description : "",
					deadline_time: this.taskData.time ? this.taskData.time : "",
					frequency: isRecurrence ? this.taskData.frequency : "",
					is_private: this.taskData.responsible && this.$auth.user().id == this.taskData.responsible.id ? this.is_private : 0,
					tags: this.taskData.selectedTags.map((el) => el.id),
					project_id: this.taskData.project ? this.taskData.project.id : "",
					estimation: this.taskData.estimation,
					duration: this.taskData.duration,
				};

				// Parameter to handle tasks from projects
				if (this.data.hasOwnProperty("from_meeting_id")) {
					object.from_meeting_id = this.data.from_meeting_id;
					object.from_meeting_step = this.data.from_meeting_step;
					object.force_update_ws = true;
				} else {
					object.from_calendar = 1;
				}

				if (this.taskData.watchers) {
					object.task_watchers = this.taskData.watchers.map((el) => el.id);
				}

				var deadlineDate = Object.keys(this.taskData.deadlineDate).length ? moment(this.taskData.deadlineDate.selectedDate, "D/M/YYYY").format("YYYY-MM-DD") : "";
				var startDate = Object.keys(this.taskData.dateOfStart).length ? moment(this.taskData.dateOfStart.selectedDate, "D/M/YYYY").format("YYYY-MM-DD") : "";

				object.start_date = isRecurrence ? startDate : deadlineDate;
				object.date = isRecurrence ? (this.data.crud == "edit" && this.oldTaskData.date ? this.data.date : startDate) : deadlineDate;
				object.deadline = deadlineDate;

				object.measurement = "";
				object.measurement_dynamically = "";

				switch (isRecurrence && this.taskData.frequency) {
					case "weekly":
						object.measurement = this.taskData.weeklyDays.map((el) => {
							return el.name;
						});
						break;
					case "monthly":
						if (this.taskData.subTypeMonthly == "day_numbers") {
							object.measurement = this.taskData.monthlyDays;
						} else {
							object.measurement_dynamically = this.taskData.monthlyTypeSequenceDay.map((el) => {
								return el.value;
							});
							object.measurement = this.taskData.weeklyDays.map((el) => {
								return el.value;
							});
							object.frequency = "monthly_dynamically";
						}
						break;
					case "yearly":
						object.measurement = this.taskData.yearlyDays;
						break;
				}

				if (type == "create") {
					this.createTask(object);
				} else if (type == "update") {
					this.checkSaveMode(object);
				}
			} else {
				this.loadingSaveTask = false;
			}
		},
		createTask(objData) {
			axios
				.post("/tasks/store", objData)
				.then(({ data }) => {
					this.dropzoneTaskId = data.data.id;

					const files = this.$refs.myVueDropzone.getQueuedFiles();

					let filesData = new FormData();

					for (var i = 0; i < files.length; i++) {
						if (files[i]) {
							let fileItem = files[i];

							if (fileItem) {
								filesData.append("files[" + i + "][file]", fileItem);
							}
						}
					}

					axios.post(`${process.env.VUE_APP_API_URL}/tasks/${this.dropzoneTaskId}/manage-files`, filesData).then((data) => {});
				})
				.finally(() => {
					this.refreshAll();
				});
		},
		checkSaveMode(objData) {
			// If editing is from the project, we only have option: all
			var paramsQuery = {};

			if (this.data.from == "project") {
				paramsQuery.all = 1;
				this.updateTask(objData, paramsQuery);
				return true;
			}

			// from_date cand se muta un task fictiv
			this.optionsSaveMode = ["current", "current_and_future", "all"];

			// We have only "current" option.
			// check if it is a simple task, if it has no frequency it is a simple task
			// if the date is from past
			// if press option with check
			if (!this.oldTaskData.frequency || (this.data.date && moment(this.data.date).isBefore(moment().startOf("day")))) {
				paramsQuery.current = 1;

				this.updateTask(objData, paramsQuery);
			} else {
				// Daca se schimba frecventa, intervalul nu mai apare optiunea current
				if (
					!arrayEquals(this.oldTaskData.measurement, objData.measurement) ||
					!arrayEquals(this.oldTaskData.measurement_dynamically, objData.measurement_dynamically) ||
					!valueEquals(this.oldTaskData.frequency, objData.frequency) ||
					!valueEquals(this.oldTaskData.deadline, objData.deadline) ||
					!valueEquals(this.oldTaskData.deadline_time, objData.deadline_time) ||
					!valueEquals(this.oldTaskData.start_date, objData.start_date)
				) {
					this.optionsSaveMode = ["current_and_future", "all"];
				}

				this.openSubModal("choose_save_mode", { objData: objData, paramsQuery: paramsQuery, activityDate: this.data.date, type: "task" });
			}
		},
		updateTask(objData, paramsQuery) {
			axios
				.post(`/tasks/${this.parentTaskId}/update`, objData, { params: paramsQuery })
				.then(() => {
					const files = this.$refs.myVueDropzone.getQueuedFiles();

					let filesData = new FormData();

					for (var i = 0; i < files.length; i++) {
						if (files[i]) {
							let fileItem = files[i];

							if (fileItem) {
								filesData.append("files[" + i + "][file]", fileItem);
							}
						}
					}

					if (this.filesToRemove.length) {
						for (let i = 0; i < this.filesToRemove.length; i++) {
							filesData.append("remove_files_ids[]", this.filesToRemove[i]);
						}
					}

					axios.post(`${process.env.VUE_APP_API_URL}/tasks/${this.parentTaskId}/manage-files`, filesData).then((data) => {});
				})
				.finally(() => {
					this.refreshAll();
				});
		},
		lightOrDark(color) {
			var r, g, b, hsp;

			color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));
			r = color >> 16;
			g = (color >> 8) & 255;
			b = color & 255;

			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

			// Using the HSP value, determine whether the color is light or dark
			if (hsp > 190) {
				return true;
			} else {
				return false;
			}
		},
		deleteTag(index) {
			this.taskData.selectedTags.splice(index, 1);
		},
		handleResponsible(userSelected) {
			if (this.taskData.responsible && this.taskData.responsible.id == userSelected.id) {
				this.taskData.responsible = "";
			} else {
				this.taskData.responsible = userSelected;
			}
		},
		checkFrequency(frequency) {
			if (this.taskData.frequency == "one_time" && frequency != "one_time") {
				this.taskData.dateOfStart = this.taskData.deadlineDate;
				this.taskData.deadlineDate = {};
			} else if (frequency == "one_time") {
				this.taskData.deadlineDate = this.taskData.dateOfStart;
				this.taskData.dateOfStart = {};
			}
		},
		toggleTimeInputs() {
			setTimeout(() => {
				$("#timeInput")
					.timepicker({ scrollDefault: "now", timeFormat: "H:i", show2400: false, step: "15" })
					.on("changeTime", () => {
						this.taskData.time = $("#timeInput").val();
					});
			}, 200);
		},
		openTimePicker() {
			this.hasEndHour = true;
			setTimeout(() => {
				$("#timeInput").timepicker("show");
			}, 210);
		},
		clearDeadlineHour() {
			this.taskData.time = "";
			this.hasEndHour = false;
		},
		selectDay(day, list, fromList = false) {
			switch (list) {
				case "weekly":
					if (this.taskData.weeklyDays.includes(day)) {
						this.taskData.weeklyDays.splice(
							this.taskData.weeklyDays.findIndex((el) => el == day),
							1
						);
					} else {
						this.taskData.weeklyDays.push(day);
					}
					break;
				case "monthly":
					if (this.taskData.monthlyDays.includes(day)) {
						this.taskData.monthlyDays.splice(
							this.taskData.monthlyDays.findIndex((el) => el == day),
							1
						);
					} else {
						this.taskData.monthlyDays.push(day);
					}
					break;
				case "yearly":
					var date = day.hasOwnProperty("date") ? moment(day.date, "D/M/YYYY").format("MM-DD") : day;

					if (this.taskData.yearlyDays.includes(date)) {
						this.taskData.yearlyDays.splice(
							this.taskData.yearlyDays.findIndex((el) => el == date),
							1
						);
						if (fromList) {
							this.taskData.dateOfYear.selectedDates.splice(
								this.taskData.dateOfYear.selectedDates.findIndex((el) => moment(el.date, "D/M/YYYY").format("MM-DD") == date),
								1
							);
						}
					} else {
						this.taskData.yearlyDays.push(date);
					}

					break;
			}
		},
		newStartDate(data) {
			this.configsDateOfFinish.limits = {
				min: data.date,
				max: "",
			};
			this.keyCalendarEndDate++;
		},
		refreshAll() {
			this.$root.$emit("refreshProjectPage");
			this.$root.$emit("refreshTaskReport");
			this.$root.$emit("refreshSplitView");
			this.$root.$emit("refresh_ToDo_List");
			this.$emit("close_modal");
		},
		closeModal() {
			if (this.checkInputs() && !this.taskError && this.loaded) {
				this.openSubModal("confirm_close", this.submodal.confirm_close);
			} else {
				this.$emit("close_modal");
			}
		},
		checkInputs() {
			var notEmpty = false;

			if (this.taskData.name != "") {
				notEmpty = true;
			}

			if (this.taskData.description != "") {
				notEmpty = true;
			}

			return notEmpty;
		},
		openSubModalFromHeader(data) {
			this.openSubModal(data.type, false, data.data);
		},
		openSubModal(type, data = false, dataFromHeader = false) {
			if (this.activeSubmodal != "" && this.activeSubmodal != type) {
				document.getElementById(`submodal_${this.activeSubmodal}`).classList.remove("active");
				this.submodal[this.activeSubmodal] = false;
			}

			if (dataFromHeader) {
				this.objDataForSubModal = dataFromHeader;
			}

			if (data) {
				this.dataChooseSaveMode = data;
			}

			this.subModalShow = true;
			this.submodal[type] = true;
			this.activeSubmodal = type;
			setTimeout(() => {
				document.getElementById("submodalContainer").classList.add("active");
				setTimeout(() => {
					document.getElementById(`submodal_${type}`).classList.add("active");
				}, 200);
			}, 0);
		},
		closeSubModal(type) {
			if (type == "choose_save_mode") {
				var buttonSubmit = document.getElementById("buttonPrimarySubmit");
				var buttonComplete = document.getElementById("buttonCompleteSubmit");
				if (buttonSubmit) {
					buttonSubmit.disabled = false;
				}
				if (buttonComplete) {
					buttonComplete.disabled = false;
				}
				this.loadingCompleteTask = false;
				this.loadingSaveTask = false;
			}

			document.getElementById(`submodal_${type}`).classList.remove("active");
			setTimeout(() => {
				document.getElementById("submodalContainer").classList.remove("active");
				setTimeout(() => {
					this.subModalShow = false;
					this.submodal[type] = false;
					this.activeSubmodal = "";
				}, 200);
			}, 200);
		},
		updateDataFromHeader(data) {
			// update taskData with values from header component
			Object.assign(this.taskData, {
				levelOfPriority: data.levelOfPriority,
				selectedTags: data.selectedTags,
				project: data.project,
				estimation: data.estimation,
				duration: data.duration,
				watchers: data.watchers,
			});
		},
		toggleViewHistory(data) {
			this.historyContent = data.show;
		},
		downloadFile(file) {
			// If has task_id, edit on parent else edit on log by task_log_id
			var paramsQuery = {};
			paramsQuery.file_token = file.file_path;

			axios.post(`/tasks/${this.parentTaskId}/download-file`, paramsQuery, { responseType: "blob" }).then((response) => {
				const contentType = response.headers["content-type"];

				let extension = "";

				// Determine the appropriate file extension based on the content type
				if (contentType === "application/pdf") {
					extension = ".pdf";
				} else if (contentType === "image/jpeg") {
					extension = ".jpg";
				} else if (contentType === "image/png") {
					extension = ".png";
				} else if (contentType === "application/msword") {
					extension = ".doc";
				} else if (contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
					extension = ".docx";
				} else if (contentType === "application/vnd.ms-excel") {
					extension = ".xls";
				} else if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
					extension = ".xlsx";
				} else {
					extension = ".file"; // Default extension if content type is unknown
				}

				// Create a Blob and URL for the file
				const blob = new Blob([response.data], { type: contentType });
				const fileURL = window.URL.createObjectURL(blob);

				// Set the file name with the appropriate extension
				const fileName = `${file.filename}`;
				// const fileName = `${this.user.first_name} ${this.user.last_name || ""}${extension}`;

				// Create the download link and initiate the download
				const fileLink = document.createElement("a");
				fileLink.href = fileURL;
				fileLink.setAttribute("download", fileName);
				document.body.appendChild(fileLink);
				fileLink.click();
			});
		},
		deleteFile(id) {
			this.filesToRemove.push(id);
			this.taskData.files = this.taskData.files.filter((file) => file.id !== id);
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}

function arrayEquals(a, b) {
	return (!a && !b) || (Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((item) => b.includes(item)) && b.every((item) => a.includes(item)));
}

function valueEquals(a, b) {
	return (!a && !b) || a == b;
}
</script>
